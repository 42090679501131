:root {
	--reach-dialog: 1;
}

[data-reach-dialog-overlay] {
	background: hsla(0, 0%, 0%, 0.33);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: auto;
}

[data-reach-dialog-content] {
	width: 50vw;
	margin: 10vh auto;
	background: $white;
	padding: 70px 2rem;
	outline: none;
	border-radius: 5px;
	position: relative;
}

.dialog-title {
	font-weight: 300;
	font-size: 28px;
	text-align: center;
	margin-bottom: 30px;
}

.dialog-description {
	font-weight: 500;
	font-size: 16px;
	text-align: center;
	margin-bottom: 35px;
}

.dialog-button-wrapper {
	display: flex;
	justify-content: space-around;
}

.dialog-button-cancel,
.dialog-button-confirm {
	color: $white;
	padding: 12px 0;
	border-radius: 5px;
	width: 180px;
}

.dialog-button-cancel {
	background-color: $red;
}

.dialog-button-confirm {
	background-color: $dark-blue;
}

.dialog-close-cross {
	position: absolute;
	top: 10px;
	right: 10px;
	width: 25px;
	height: 25px;
	&:hover {
		cursor: pointer;
	}
}
