.add-survey.titles {
	.btn.btn-blue {
		position: absolute;
		right: 0;
	}
}

.add-titles-title {
	font-size: 26px;
	font-weight: 500;
	padding-bottom: 20px;
}

.add-titles-subtitle {
	font-size: 16px;
	font-weight: 500;
	line-height: 1.25;
	text-align: center;
	width: 50%;
}
