.contest-finished-container {
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	text-align: center;
	width: 70%;
	box-shadow: -7px 14px 16px 0 rgba(230, 237, 239, 0.54);
	border: solid 1px #f1f1f5;
	margin: auto;
	margin-bottom: 30px;
	padding-top: 60px;
	padding-bottom: 40px;
	& h1 {
		font-size: 28px;
		font-weight: 300;
		margin-bottom: 30px;
		&:first-child {
			margin-bottom: 20px;
		}
	}
	& p {
		font-size: 18px;
		font-weight: 500;
	}
	& button {
		margin-top: 30px;
		margin-bottom: 50px;
	}
}
