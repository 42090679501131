.company-btn-back {
	display: flex;
	width: 78px;
	text-decoration: none;
	justify-content: space-between;
	align-items: center;

	img {
		margin-left: -5px;
	}

	p {
		font-family: 'Rubik', sans-serif;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 2.36;
		color: #000000;
	}
}

.company-container-label {
	padding: 25px 0;
	font-family: Rubik;
	font-size: 26px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 0.92;
	letter-spacing: normal;
	color: #282d32;
}

.company-container-card {
	display: flex;
}

.company-card {
	display: flex;
	justify-content: space-between;
	background-color: $white;
	height: 189px;
	box-shadow: 0 2px 11px 0 $sidebar-gradient-gray-right;
	padding: 44px 0 44px 30px;
	font-family: 'Rubik', sans-serif;
}

.company-content-account-logo {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	height: 101px;
	width: 101px;
	border: 1px solid transparent;
	border-radius: 50%;
	box-shadow: 0 4px 36px 0 rgba(37, 38, 94, 0.1);
	background-color: #f7465f;
	img {
		box-shadow: 0 4px 36px 0 rgba(37, 38, 94, 0.1);
		background-color: #f7465f;
	}

	span {
		font-size: 32.4px;
		color: $white;
	}
}

.company-card-content {
	padding-top: 6px;
	padding-left: 132px;
	h2 {
		margin-bottom: 10px;
		font-size: 18px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.83;
		letter-spacing: normal;
		color: #000000;
	}

	h3 {
		margin-top: 10.5px;
		font-size: 14px;
		font-weight: 500;
		line-height: 2.36;
		color: #000000;
	}

	hr {
		width: 167px;
		border: solid 1px #e4e4e5;
	}
}

.company-delete-field {
	margin-top: -24px;
	padding-right: 20px;
}

.company-card-icon {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		padding-right: 10px;
	}

	span {
		padding-top: 1px;
		font-size: 12px;
		font-weight: 500;
		text-align: right;
		color: #05ace9;
		text-align: right;
	}
}

.company-container-spinner {
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid $pale-grey;
	border-right: 1px solid transparent;
	border-left: 1px solid transparent;
	font-size: 14px;
	height: 600px;
}
