.page-container.interviewer {
	.details-container-title {
		margin: 0;
	}
	.add-current-view {
		border-bottom: 1px solid $pale-grey;
	}
}
.interviewer-data-wrapper {
	display: flex;
	justify-content: center;
	border: 1px solid $pale-grey;
	padding: 54px 0 47px;
}
.interviewer-data-container {
	display: flex;
	flex-flow: row wrap;
}
.interviewer-avatar {
	margin-right: 31px;
}
.interviewer-info-top {
	font-weight: bold;
	font-size: 18px;
	padding-bottom: 12px;
	border-bottom: 2px solid $pale-grey;
}
.interviewer-info-bottom {
	padding-top: 5px;
	font-size: 18px;
	.email {
		font-size: 14px;
		font-weight: bold;
	}
	.completed-surveys,
	.completed-surveys * {
		font-size: 14px;
	}
	.completed-surveys-first-value {
		font-weight: bold;
	}
	.completed-surveys {
		margin-top: 5px;
	}
}
