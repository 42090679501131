.type-selector-container {
	position: relative;
}

.cancel-button {
	position: absolute;
	right: 30px;
	font-size: 12px;
	font-weight: 500;
	color: $blue;
	text-transform: uppercase;
	cursor: pointer;
}

.details-text,
.details-questions,
.details-integration {
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 30px;
	margin-top: 17px;
}

.details-radios {
	display: flex;
	flex-flow: row wrap;
	position: relative;
	z-index: 10;
	margin: 0 40px;
}

.details-radios.text {
	justify-content: space-between;
	border-bottom: solid 1px $grey;
}
.details-radios.questions {
	justify-content: space-between;
	border-bottom: solid 1px $grey;
}
.details-radios.integration {
	justify-content:flex-start;
}
.details-radio {
	margin: 0 24px 30px 0;
}

/* .details-radio:nth-child(6n) {
    margin-right: 0;
} */

.details-radio input[type='radio'] {
	display: none;
}

.details-radio label {
	line-height: 20px;
	position: relative;
	display: flex;
	justify-content: center;
	height: 100%;
}

.details-radio-label {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $white;
	border: 1px solid $grey;
	border-radius: 5px;
	width: 150px;
	padding: 20px 0;
}

.details-radio-label:hover {
	border: 1px solid $blue;
	background-color: $pale-blue;
	box-shadow: 0 2px 6px 0 rgba(97, 97, 97, 0.22);
	cursor: pointer;
}

.add-survey-img {
	padding-bottom: 30px;
	width: 22px;
}

.details-radio-title {
	font-size: 12px;
	font-weight: 500;
}
