/* SURVEYS LIST BANNER */
.payment-banner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid $pale-grey;
	box-shadow: -7px 14px 16px 0 rgba(230, 237, 239, 0.54);
	background-color: $white;
	padding: 35px 40px;
}

.payment-banner-content {
	display: flex;
	align-items: center;
}

.payment-banner-content img {
	height: 49px;
	width: 56px;
}

.payment-banner-loader {
	margin: auto;
}

.payment-banner-content-text {
	h2 {
		font-size: 28px;
		font-weight: 300;
		color: $black;
		margin-bottom: 12px;
	}
	p {
		font-size: 18px;
		font-weight: 500;
		color: $text-grey;
	}
}

// Interviewers
.payment-banner-interviewers {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid $pale-grey;
	box-shadow: -7px 14px 16px 0 rgba(230, 237, 239, 0.54);
	background-color: $white;
	padding: 35px 40px;
	&-center {
		justify-content: center;
	}
}

.payment-banner-interviewers-content {
	display: flex;
	align-items: center;
	width: 100%;
}

.payment-banner-interviewers-dashboard {
	h2 {
		font-size: 28px;
		font-weight: 300;
		color: $black;
		margin-bottom: 12px;
	}
	p {
		font-size: 18px;
		font-weight: 500;
		color: $text-grey;
	}
}

.payment-banner-interviewers-dashboard-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}

.payment-banner-interviewers-survey-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.payment-banner-interviewers-survey-image {
	width: 30px;
	height: 30px;
	margin-right: 45px;
}

.payment-banner-interviewers-survey {
	h2 {
		font-size: 18px;
		font-weight: 500;
		color: $black;
		margin-bottom: 12px;
	}
	p {
		font-size: 28px;
		font-weight: 300;
		color: $black;
	}
}

.payment-banner-interviewers-questionnaire-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	justify-content: space-between;
}

.payment-banner-interviewers-questionnaire {
	margin-right: 20px;
	h2 {
		font-size: 28px;
		font-weight: 300;
		color: $black;
		margin-bottom: 12px;
	}
	p {
		font-size: 14px;
		font-weight: normal;
		max-width: 800px;
		color: $black;
	}
}
