.common-popup-mask {
	position: fixed;
	z-index: 1000;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: white;
	opacity: 0.8;
}

.common-popup-container {
	position: fixed;
	z-index: 10000;
	margin: auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 760px;
	height: 425px;
	border: 1px solid #ceced4;
	border-radius: 4px;
	box-shadow: 0 11px 38px 0 rgba(87, 87, 87, 0.13);
	background-color: white;
}

.common-popup-content {
	display: flex;
	align-items: center;
	height: 100%;
	padding: 40px 50px;
}

.common-popup-postpone {
	position: absolute;
	right: 0;
	text-align: end;
	margin: 20px 30px 0 0;
	font-size: 12px;
	font-weight: 500;
	color: $blue;
	cursor: pointer;
}

.common-popup-container-texts {
	width: 55%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.common-popup-container-title {
	font-size: 28px;
	font-weight: 300;
	margin-bottom: 30px;
}

.common-popup-container-subtitle {
	font-size: 28px;
	font-weight: 300;
}

.common-popup-container-text {
	margin-bottom: 50px;
	font-size: 18px;
	font-weight: 500;
}

.common-popup-container-image {
	width: 45%;
	display: flex;
	justify-content: center;
}
