.auth-layout-container {
	background-color: $background-edge;
	display: flex;
	justify-content: center;
	padding-bottom: 50px;
}  
.auth-layout-titles {
	img {
		margin: 50px 0 70px;
		
	}
	&-top {
		display: flex;
		justify-content: space-between;
	}
}
.auth-layout-title {
	font-size: 28px;
	font-weight: 700;
	margin-bottom: 10px;
}


.auth-layout-subtitle {
	font-size: 21px;
	font-weight: 500;
}
.auth-layout-content {
	display: flex;
}
.auth-layout-content-image {
	width: 50%;
	margin-top: 40px;
	img {
		height: 480px;
	}
}

.auth-layout-content-information {
	width: 50%;
	margin-left: 100px;
}