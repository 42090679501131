.add-current-view {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
	padding: 20px 250px;
	background-color: #f6f6fa;
	text-transform: uppercase;
}

.add-current-view-options,
.add-current-view-adding,
.add-current-view-summary {
	font-size: 12px;
	font-weight: 500;
}

.add-current-view-arrow {
	width: 8px;
	height: 8px;
	border-bottom: 2px solid #939698;
	border-right: 2px solid #939698;
	transform: rotate(315deg);
}

.active-text-highlighted {
	font-weight: 900;
}

@media only screen and (max-width: 1280px) {
	.add-current-view {
		padding: 20px 120px;
	}
}
