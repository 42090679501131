.details-container-page-add {
	padding-bottom: 40px;
}

.page-container-draggable-area {
	position: relative;
}

.page-container-mask {
	&-main {
		width: 100%;
		background-color: $white;
		opacity: 0.5;
		position: absolute;
		height: 100%;
		z-index: 1;
	}
	&-button {
		width: 100%;
		background-color: $white;
		opacity: 0.5;
		position: absolute;
		height: 25px;
	}
}
