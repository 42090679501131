.rodo-popup-container {
	& {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		height: 100vh;
		background: rgba(0, 0, 0, 0.9);
		z-index: 1000;
		transition: all 0.5s ease;
		opacity: 0;
		visibility: hidden;
		transform: translate(0, -100px);
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
	}
	&.open {
		height: 100vh;
		opacity: 1;
		visibility: visible;
		transform: translate(0, 0);
	}
}
.rodo-popup {
	width: 80%;
	max-height: 80%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
}
.rodo-content {
	& {
		color: white;
		max-height: 80%;
		overflow: auto;
		font-size: 32px;
	}
}
.form-button {
	margin: 25px;
	font-size: 28px;
	color: #f5f5f5;
}
