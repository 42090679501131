.media-details-container {
	padding-bottom: 70px;
	border-bottom: 1px solid $grey;
}

.media-details-title {
	font-size: 16px;
	font-weight: 500;
	text-align: left;
	margin: 30px 0;
}

.media-details-upload {
	width: 55%;
	height: 90px;
	margin: 0 auto;
	border: 2px dashed lightgray;
	&.image-video {
		height: 250px;
	}
}

.media-details-upload-input {
	display: none;
}

.media-details-upload-label {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.media-details-upload-text {
	font-size: 12px;
	font-weight: 500;
	color: rgba(40, 45, 50, 0.5);
	text-align: center;
	cursor: pointer;
	&.blue-color {
		color: $blue;
	}
}

.media-details-image-container {
	border: 1px solid black;
	width: 100%;
	height: 282px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.media-details-image {
	max-width: 100%;
	max-height: 280px;
}

// AUDIO

.media-details-audio-container {
	display: flex;
	justify-content: center;
}

.media-details-audio-content {
	width: 400px;
	position: relative;
}

// VIDEO

.media-details-video-container {
	display: flex;
	justify-content: center;
}

.media-details-video-content {
	width: 400px;
	position: relative;
}
