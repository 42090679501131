.contests-add-container {
	padding-bottom: 65px;
}

.contests-add-back {
	display: flex;
	align-items: center;
	padding: 63px 0 37px;
	width: 100px;
	font-size: 14px;
	color: $black;
	text-decoration: none;
	cursor: pointer;
	img {
		margin-right: 18px;
	}
}

.contests-add-title {
	font-size: 26px;
	font-weight: 500;
	padding-bottom: 43px;
}

.contests-add-name-container {
	padding: 35px 50px;
	background-color: $sidebar-gradient-gray-left;
}

.contests-add-name-container-text {
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 30px;
	&:last-of-type {
		margin-top: 42px;
	}
}

.contests-add-name-container-input {
	width: 100%;
	height: 43px;
	padding: 0 27px;
	font-size: 14px;
	font-family: Rubik, sans-serif;
}

.contests-add-question-container {
	padding-bottom: 30px;
	margin-top: 65px;
	background-color: $sidebar-gradient-gray-left;
}

.contests-add-question-container-title {
	font-size: 16px;
	font-weight: 500;
	padding: 26px 0 26px 50px;
}

.contests-add-question-box {
	border: 1px solid $graphite;
	background-color: $white;
	padding: 0 30px;
	margin: 0 20px;
}

.contests-add-question-type {
	display: flex;
	border-bottom: 1px solid $graphite;
	padding: 21px 0 23px;
	p {
		font-size: 14px;
		font-weight: 500;
		margin-left: 15px;
	}
}

.contests-add-question-content {
	border-bottom: 1px solid $graphite;
	padding: 34px 0 31px;
	input {
		width: 100%;
		height: 43px;
		padding-left: 27px;
	}
}

.contests-add-question-content-text {
	font-size: 16px;
	font-weight: 500;
	padding-bottom: 30px;
}

.contests-add-question-content-additional {
	font-size: 10px;
	font-weight: 300;
	color: rgba(40, 45, 50, 0.5);
	padding-top: 10px;
	&:nth-of-type(2) {
		padding-bottom: 22px;
	}
}

.contests-add-answers-content {
	border-bottom: 1px solid $graphite;
	margin: 34px 0 31px;
}

.contests-add-answers-content-title {
	font-size: 16px;
	font-weight: 500;
}

.contests-add-answers-content-subtitle {
	padding: 36px 0 30px;
}

// ANSWERS
.contests-answers-label {
	position: relative;
	margin-left: 50px;
	&:before {
		content: '';
		position: absolute;
		left: -35px;
		width: 20px;
		height: 20px;
		box-sizing: border-box;
		border: 1px solid $black;
		border-radius: 50%;
		background: white;
	}
	&:after {
		content: '';
		position: absolute;
		top: 4px;
		left: -31px;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background-color: $blue;
		transform: scale(0);
		transition: transform 0.25s ease;
	}
}

.contests-answers-radio {
	display: none;
}

.contests-answers-radio:checked + .contests-answers-label:before {
	border-color: $blue;
}

.contests-answers-radio:checked + .contests-answers-label:after {
	transform: scale(1);
}

.contests-answers-circles {
	margin-left: 25px;
}

.contests-add-answers-buttons {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 34px;
	.btn.btn-red {
		margin-right: 10px;
		font-weight: 400;
	}
}
