.panelist-summary-information-container {
	display: flex;
	justify-content: space-between;
}

.panelist-summary-information-box {
	width: calc(33.33333% - 80px / 3);
	margin-right: 40px;
	padding: 25px;
	border: 1px solid $grey;
	box-shadow: 0 2px 11px 0 $sidebar-gradient-gray-right;
	background-color: $white;
	& > p {
		font-size: 18px;
		font-weight: 700;
	}
}

.panelist-summary-information-box:nth-child(3n) {
	margin-right: 0;
}

.panelist-summary-information-box-label {
	display: flex;
	justify-content: space-between;
	align-content: center;
	margin-top: 15px;
	p {
		font-size: 14px;
		color: $text-grey;
	}
	.panelist-summary-filled {
		color: green;
		&.not-filled {
			color: $red;
		}
	}
}

.panelist-summary-bullet-filled {
	display: block;
	width: 14px;
	height: 14px;
	border-radius: 7px;
	background-color: green;
	margin-right: 9px;
	&.not-filled {
		background-color: $red;
	}
}
