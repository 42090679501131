.companies-list-container {
    padding-top: 63px;
}

.companies-list-title {
    font-size: 26px;
    font-weight: 500;
    padding: 63px 0 40px;
}

.companies-list-name.bold,
.companies-list-sort-created,
.companies-list-sort-end {
    position: relative;
    border: 1px solid white;
}
/* 
.companies-list-name.bold:hover,
.companies-list-sort-created:hover,
.companies-list-sort-end:hover {
    background-color: $white;
    border-color: $grey;
    border-radius: 2px;
    cursor: pointer;
} */

/* .companies-list-name.bold:hover:after,
.companies-list-sort-created:hover:after,
.companies-list-sort-end:hover:after {
    content: '';
    width: 8px;
    height: 8px;
    border-bottom: 2px solid $red;
    border-right: 2px solid $red;
    transform: rotate(45deg);
    position: absolute;
    top: 8px;
    right: 25px;
} */

.companies-list-name.bold.selected,
.companies-list-sort-created.selected,
.companies-list-sort-end.selected {
    background-color: $white;
    border-color: $grey;
    border-radius: 2px;
}

/* .companies-list-name.bold.selected:after,
.companies-list-sort-created.selected:after,
.companies-list-sort-end.selected:after {
    content: '';
    width: 8px;
    height: 8px;
    border-bottom: 2px solid $red;
    border-right: 2px solid $red;
    transform: rotate(45deg);
    position: absolute;
    top: 8px;
    right: 25px;
} */

.companies-list-name.bold.selected.arrow-transformation:after,
.companies-list-sort-created.selected.arrow-transformation:after,
.companies-list-sort-end.selected.arrow-transformation:after {
    transform: rotate(225deg);
    top: 12px;
}

.companies-list-sort {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex: 1 1 70%;
    height: 80px;
}

.companies-list-sort p {
    flex: 1 1 25%;
    text-align: center;
}

.companies-list-sort-status.colored {
    font-weight: 500;
    color: $blue;
}

.companies-list-name-container {
    flex: 1 1 30%;
}

.companies-list-name-container.bold {
    display: flex;
    justify-content: center;
}

.companies-list-name.bold {
    width: 47%;
    justify-content: center;
}

.companies-list-name.bold,
.companies-list-sort.bold p {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}

.companies-list-name {
    display: flex;
    padding-left: 20px;
    align-items: center;
}
.companies-list-name-container.position {
    height: 80px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

// Interviewers

.companies-list-interviewers-main {
    text-decoration: none;
    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f1f1f5;
    border-right: 1px solid white;
    border-left: 1px solid white;
    font-size: 14px;
    width: 100%;
}

.companies-list-interviewers-labels {
    display: flex;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    border-bottom: 1px solid #f1f1f5;
    border-right: 1px solid white;
    border-left: 1px solid white;
    padding-bottom: 20px;
}

.survey-list-interviewers-container {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    &.link {
        cursor: pointer;
    }
}

.companies-list-interviewers-name {
    flex: 5;
}

.companies-list-interviewers-created,
.companies-list-interviewers-amount,
.companies-list-interviewers-points{
    display: flex;
    justify-content: center;
    flex: 2;
}

.companies-list-interviewers-status {
    font-size: 12px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    flex: 1;
    text-transform: uppercase;
    &.red {
        color: $red;
    }
    &.blue {
        color: $blue;
    }
    &.label {
        font-weight: bold;
    }
}

.companies-searchbar-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $pale-grey;
    box-shadow: -7px 14px 16px 0 rgba(230, 237, 239, 0.54);
    background-color: $white;
    padding: 24px 0;
}

.companies-list-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $pale-grey;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
    font-size: 14px;
    height: 300px;
}