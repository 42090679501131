.element-saving-mask {
	background-color: $white;
	opacity: 0.9;
	position: absolute;
	width: 100%;
	height: 100%;
	right: 0px;
	z-index: 30;
	display: none;
	&.active {
		display: block;
	}
}

.details-detail-question-slider {
	position: absolute;
	width: 100%;
	height: 5px;
	overflow-x: hidden;
	bottom: 0;
	left: 0;
	z-index: 50;
}

.details-detail-question-line {
	position: absolute;
	opacity: 0.4;
	background: #4a8df8;
	width: 150%;
	height: 5px;
}

.details-detail-question-subline {
	position: absolute;
	background: #4a8df8;
	height: 5px;
}
.inc {
	animation: increase 2s infinite;
}
.dec {
	animation: decrease 2s 0.5s infinite;
}

@keyframes increase {
	from {
		left: -5%;
		width: 5%;
	}
	to {
		left: 130%;
		width: 100%;
	}
}
@keyframes decrease {
	from {
		left: -80%;
		width: 80%;
	}
	to {
		left: 110%;
		width: 10%;
	}
}

.details-detail-question-saving {
	position: fixed;
	bottom: 0;
	right: 0;
	background-color: $white;
	padding: 20px;
	opacity: 0.5;
	z-index: 50;
}
