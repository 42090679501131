.details-detail-header {
	display: flex;
	justify-content: space-between;
	padding: 17px 0;
	border-bottom: 1px solid $pale-grey;
	&.small {
		margin-left: 30px;
		margin-right: 30px;
	}
}

.details-detail-header-label {
	line-height: 27px;
	margin-left: 10px;
}

.details-detail-header-name {
	display: flex;
	align-items: center;
}

.details-detail-header-button,
.details-detail-header-dropdown button {
	position: relative;
	color: $blue;
	font-size: 12px;
	font-weight: 600;
	width: 67px;
	height: 27px;
	border: none;
	border-radius: 3px;
	background-color: $white;
	&:hover {
		background-color: $pale-blue_2;
		.details-detail-header-button-signature {
			right: 1px;
			top: 1px;
		}
	}
	&.highlighted {
		background-color: $pale-blue_2;
		border: 1px solid $blue;
	}
}

.details-detail-header-button-signature {
	position: absolute;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: red;
	right: 2px;
	top: 2px;
}

.details-detail-header-button {
	margin-left: 5px;
	&:hover {
		border: 1px solid $blue;
	}
}

.details-detail-header-dropdown {
	margin-left: 5px;
	display: inline;
}

.details-detail-header-dropcontent {
	width: 250px;
	box-shadow: 0 2px 12px 0 rgba(230, 230, 230, 0.5);
	border: 1px solid $grey;
	background-color: $white;
	display: none;
	flex-direction: column;
	position: absolute;
	right: 30px;
	z-index: 20;
}

.details-detail-header-dropcontent button {
	height: 50px;
	width: 100%;
	border-radius: 0;
}

.details-detail-header-dropdown:hover .details-detail-header-dropcontent {
	display: flex;
}

.details-detail-header-dropcontent button:not(:nth-child(3)) {
	border-bottom: 1px solid $grey;
}
