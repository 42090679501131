.add-survey-radios {
	display: flex;
	flex-flow: row wrap;
	position: relative;
	z-index: 10;
}

.add-survey-radio,
.add-survey-checkbox {
	padding: 0 40px;
}

.add-survey-radio input[type='radio'],
.add-survey-checkbox input[type='checkbox'] {
	display: none;
}

.add-survey-radio label,
.add-survey-checkbox label {
	padding: 0 0 0 40px;
	line-height: 20px;
	position: relative;
	display: flex;
}

.add-survey-radio label:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
	box-sizing: border-box;
	border: 1px solid $black;
	border-radius: 50%;
	background: white;
}

.add-survey-checkbox label:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
	box-sizing: border-box;
	border: 1px solid $black;
	border-radius: 0;
	background: white;
}

.add-survey-radio label:after {
	content: '';
	position: absolute;
	top: 4px;
	left: 4px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: $blue;
	transform: scale(0);
	transition: transform 0.25s ease;
}

.add-survey-checkbox label:after {
	content: '';
	position: absolute;
	top: 3px;
	left: 3px;
	width: 15px;
	height: 13px;
	background: transparent url('../../../../assets/surveys/questions/check.svg')
		no-repeat center center;
	background-size: 15px 13px;
	transform: scale(0);
	transition: transform 0.25s ease;
}

.add-survey-radio input[type='radio']:checked + label:before,
.add-survey-checkbox input[type='checkbox']:checked + label:before {
	border-color: $pale-blue;
}

.add-survey-radio input[type='radio']:checked + label:after,
.add-survey-checkbox input[type='checkbox']:checked + label:after {
	transform: scale(1);
}

.add-survey-status-radio-label {
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 1px solid $grey;
	border-radius: 5px;
	width: 150px;
	padding: 20px 0;
}

.add-survey-radio
	input[type='radio']:checked
	+ label
	.add-survey-status-radio-label {
	border-color: $blue;
	background-color: $pale-blue;
}

.add-survey-radio.two:first-child {
	border-right: 1px solid $pale-grey;
}

.add-survey-radio.two:nth-child(2) {
	padding-left: 30px;
}
