.add-survey-name-text,
.add-survey-date-text,
.add-survey-amount-number {
	width: 55%;
	padding: 10px;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.add-survey-name-subtitle {
	font-size: 10px;
	font-weight: 300;
	color: $text-grey;
	padding-top: 10px;
}
