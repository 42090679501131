.header-language {
	display: flex;
	list-style-type: none;
	.flag-icon {
		max-width: 35px;
		max-height: 35px;
	}
	.inactive {
		opacity: 0.5;
	}
}
