.preview-layout-container {
	padding: 0 100px;
	background-color: #fcfdff;
	height: 100%;
}
.preview-button {
	flex-shrink: 0;
	margin-left: 20px;
}
.headerlanguage-panelist {
	margin-top: 15px;
	margin-bottom: -15px;
	display: flex;
	justify-content: flex-end;
}

.preview-back-container,
.panelist-summary-back-container {
	display: flex;
	align-items: center;
	margin: 70px 0;
	width: 100px;
	cursor: pointer;
	p {
		font-size: 14px;
		padding-left: 18px;
	}
}

.preview-element {
	border-bottom: 1px solid $grey;
	&.completion-limit{
		border-width: 0;
		padding:0;
		margin:0;
	}
	
	& .element-summary-texts {
		font-size: 16px;
		font-weight: 400;
		padding-bottom: 40px;
	}
	& .element-summary-questions-title {
		padding-top: 40px;
	}
	& .contest {
		padding-top: 20px;
		margin-top: 20px;
		border-top: 1px solid $grey;
	}
	& .add-survey-radio {
		padding: 0 0 30px 0;
		& label {
			display: block;
		}
	}
	& .another {
		display: flex;
		align-items: center;
	}
	& .add-survey-checkbox {
		padding: 0 0 30px 0;
		& label {
			display: block;
		}
	}
	& .choice-details-item {
		width: 100%;
		display: flex;
		margin-bottom: 30px;
		label {
			text-align: left;
			width: 100px;
			margin-right: 10px;
			word-wrap: break-all;
		}
	}
	& .details-questions-matrix-body {
		& .add-survey-checkbox {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 145px;
			width: 100%;
			border: 1px solid $grey;
			margin: 0;
			border-top: none;
			border-left: none;
		}
		& .add-survey-radio {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 145px;
			width: 100%;
			border: 1px solid $grey;
			margin: 0;
			border-top: none;
			border-left: none;
		}
	}
}
.preview-layout-container {
	.add-titles-title {
		padding-bottom: 0;
	}
	.details-container-title {
		margin-top: 37px;
		.details-container-name {
			margin: 49px 0 10px 19px;
		}
		.preview-header-container-description {
			margin: 0 0 49px 19px;
		}
		.preview-header-container-logo {
			margin-right: 4%;
			margin-left: 4%;
			img {
				width: 150px;
			}
		}
	}
}

.preview-send-container {
	display: flex;
	justify-content: center;
	margin: 20px 0;
}

.preview-send-mask {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: white;
	opacity: 0.8;
	top: 0;
	left: 0;
	z-index: 10;
}

.preview-send-popup {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	& p {
		background-color: $white;
	}
	z-index: 20;
}

.preview-send-btn {
	margin: 20px 0;
}

.preview-element-error-msg {
	color: $red;
	font-size: 12px;
	min-height: 20px;
}

.preview-element-error-msg:last-child {
	margin-bottom: 20px;
	
}

// RANKING
.element-summary-ranking-question.active {
	&:active {
		background-color: $pale-blue;
		border-color: $blue;
	}
}

.preview-ranking-element {
	width: 100%;
	border: 1px solid $text-grey;
	padding: 12px 0;
	margin-bottom: 40px;
	font-size: 14px;
	text-align: center;
}

.preview-header-panelist {
	margin-top: 70px;
	margin-bottom: 40px;
	font-size: 26px;
	font-weight: 500;
}

.preview-back-container-panelist {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 70px;
	width: 100px;
	text-decoration: none;
	color: #000;
	cursor: pointer;
	margin-top: 65px;
	p {
		padding-left: 18px;
	}
}

@media screen and (max-width: 767px) {
	.preview-layout-container {
		padding: 0 15px;
		
	}
  }