.register-finalize-container {
	background-color: $background-edge;
	padding-bottom: 68px;
	.group {
		display: flex;
		flex-direction: column;
		position: relative;
		small {
			position: absolute;
			color: #f00;
			bottom: 10px;
		}
	}
}

.register-finalize-header {
	height: 108px;
	border-bottom: 1px solid $pale-grey;
	display: flex;
	align-items: center;
	margin-bottom: 60px;
}

.header-icon {
	display: flex;
	justify-content: space-between;
	flex: 1 1 85%;
	padding-left: 40px;
	margin-right: 25px;
}

.header-user-container {
	height: 100%;
	border-left: 1px solid $pale-grey;
}

.register-finalize-layout {
	padding: 0 155px;
	padding-top: 60px;
}

// BANNER
.register-finalize-banner-container {
	border: 1px solid $pale-grey;
	box-shadow: -7px 14px 16px 0 rgba(230, 237, 239, 0.54);
	background-color: $white;
	padding: 25px 40px;
}

.register-finalize-banner-texts {
	h2 {
		font-size: 28px;
		font-weight: 300;
		margin-bottom: 20px;
	}
	p {
		font-size: 18px;
		font-weight: 500;
	}
}

// COMMON
.register-finalize-content {
	margin-top: 76px;
	position: relative;
}

.register-finalize-step {
	position: absolute;
	left: -37px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 32px;
	font-weight: 500;
	width: 68px;
	height: 68px;
	border: 1px solid $grey;
	background-color: $background-edge;
	border-radius: 50%;
	&.first,
	&.second,
	&.third {
		border-color: $blue;
		background-color: $blue;
		color: $white;
	}
}

.register-finalize-section-title {
	font-size: 26px;
	font-weight: 500;
	padding-top: 30px;
	border-top: 1px solid $grey;
}

.register-finalize-section-subtitle {
	font-size: 16px;
	font-weight: 500;
	margin: 30px 0;
}

// CONTACT
.register-finalize-contact {
	position: relative;
	border-left: 1px solid $grey;
	padding-left: 108px;
	input {
		width: 100%;
		height: 42px;
		padding-left: 26px;
		margin-bottom: 30px;
		border: 1px solid $grey;
		border-radius: 4px;
		&::placeholder {
			font-family: Rubik;
			font-size: 14px;
		}
	}
	&.first {
		border-left: 2px solid $blue;
	}
}

.register-finalize-contact-container {
	width: 75%;
}

.register-finalize-contact-inputs {
	display: flex;
	justify-content: space-between;
	input {
		width: 48%;
	}
}

// PACKET
.register-finalize-packet {
	position: relative;
	border-left: 1px solid $grey;
	padding-left: 108px;
	&.second {
		border-left: 2px solid $blue;
	}
}

.register-finalize-packet-container {
	display: flex;
}

.register-finalize-packet-box {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	border: 1px solid $grey;
	background-color: $white;
	width: calc(33.333333% - 92px / 3);
	margin: 0 46px 55px 0;
	&:nth-child(3) {
		margin-right: 0;
	}
	&.selected {
		border-color: $blue;
	}
	&.pointer {
		cursor: pointer;
	}
}

.register-finalize-packet-options-content {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.register-finalize-packet-title {
	width: 90%;
	font-size: 16px;
	font-weight: 700;
	text-align: center;
	margin: 14px 0 40px;
	padding: 20px 0 17px;
	background-color: $background-middle;
}

.register-finalize-packet-options {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.register-finalize-packet-options-element {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
	&:last-child {
		margin-bottom: 0;
	}
}

.register-finalize-packet-icon {
	margin: 0 23px 0 48px;
}

.register-finalize-packet-option {
	margin-right: 48px;
}

.register-finalize-packet-price-content {
	margin: 60px 0;
}

.register-finalize-packet-price {
	font-size: 31px;
	font-weight: bold;
	color: $blue;
	text-align: center;
}

// SUMMARY
.register-finalize-summary {
	position: relative;
	border-left: 1px solid $grey;
	padding-left: 108px;
	&.third {
		border-left: 2px solid $blue;
	}
}

.register-finalize-summary-container {
	border-bottom: 1px solid $grey;
}

.register-finalize-summary-content {
	display: flex;
	width: 100%;
	border: 1px solid $grey;
	background-color: $white;
	padding: 0 10px;
	margin-bottom: 45px;
}

.register-finalize-summary-payment {
	width: 65%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 40px;
	font-weight: 700;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		right: -60px;
		transform: rotate(90deg);
		height: 1px;
		width: 80px;
		background: $grey;
		margin-left: 38px;
	}
}

.register-finalize-summary-amount {
	width: 35%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 40px 0;
	font-weight: 700;
	p.blue {
		font-size: 22px;
		color: $blue;
	}
}

// BUTTONS
.register-finalize-buttons {
	display: flex;
	justify-content: flex-end;
	margin-top: 40px;
	button:first-of-type {
		margin-right: 14px;
	}
	button.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
}

// Panelists
.register-finalize-panelist {
	position: relative;
	border-left: 1px solid $grey;
	padding-left: 108px;
	padding-bottom: 1px;
	&.highlight {
		border-left: 2px solid $blue;
	}
	&-inner {
		padding-top: 20px;
		border-top: 1px solid $grey;
		&.last {
			border-bottom: 1px solid $grey;
		}
		h1 {
			font-size: 26px;
			font-weight: 500;
			margin-bottom: 40px;
		}
	}
}

.register-finalize-step-panelist {
	position: absolute;
	left: -37px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 32px;
	font-weight: 500;
	width: 68px;
	height: 68px;
	border: 1px solid $grey;
	background-color: $background-edge;
	border-radius: 50%;
	&.highlight {
		border-color: $blue;
		background-color: $blue;
		color: $white;
	}
}

.register-finalize-footer {
	display: flex;
	justify-content: center;
	padding: 30px 50px;
	width: 100%;
	background-image: linear-gradient(105deg, $violet, $dark-violet);
	color: #fff;
	h2 {
		font-size: 43px;
		padding: 50px 0 35px 0;
	}
	p {
		font-size: 19px;
		line-height: 30px;
	}
	.footer-nav {
		display: flex;
		justify-content: space-between;
		max-width: 1024px;
		width: 100%;
		&-text {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 22px;
			a {
				color: white;
				text-decoration: none;
			}
			a:hover {
				color: darken(#fff, 10);
				@include transition(0.25s, color, ease);
			}
			&-address {
				display: flex;
				flex-direction: column;
				font-size: 22px;
			}
		}
		&-social {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100px;
		}
	}
}
