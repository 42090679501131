.panelists-list-title {
	h2 {
		font-size: 26px;
		font-weight: 500;
		color: $black;
	}
	p {
		font-size: 16px;
		line-height: 1.5;
	}
}
.panelists-list-header {
	font-size: 26px;
	font-weight: 500;
	line-height: 0.92;
	margin: 40px 0 30px;
}

.panelists-list-file-dropzone-container {
	display: flex;
	padding: 40px 0;
	border-top: 2px solid $pale-grey;
	//border-bottom: 2px solid $pale-grey;
	margin-top: 63px;
}

.panelists-list-file-dropzone-label {
	& {
		flex: 1;
		padding-top: 20px;
	}
	span {
		font-size: 16px;
		font-weight: 500;
		line-height: 1.5;
		margin-left: 20px;
	}
}

.panelists-list-file-dropzone {
	& {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		border: 2px dashed $pale-grey;
		border-radius: 2px;
		height: 150px;
		background-color: $white;
		text-align: center;
		.dropzone {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			width: 100%;
			height: 100%;
		}
	}
	&.isDragReject {
		border-color: $red;
	}
	> div {
		&:focus {
			outline: none;
		}
		&:hover {
			cursor: pointer;
		}
	}
}

.panelists-list-file-dropzone-spacer {
	flex: 1;
}

.panelists-list-file-dropzone-description {
	& {
		text-align: center;
		font-size: 12px;
		font-weight: 500;
		color: $text-grey;
	}
	.text-blue {
		color: $blue;
	}
	.point-after:after {
		content: '. ';
		color: $text-grey;
	}
	.space-before:before {
		content: ' ';
	}
}

.panelists-list-bottom-buttons-wrapper {
	& {
		display: flex;
		justify-content: flex-end;
		margin: 170px 0 40px;
		//TEMPORARY - TO REMOVE
		margin-bottom: 500px;
		//TEMPORARY - TO REMOVE
	}
	.btn {
		box-shadow: 0 2px 6px 0 rgba(179, 179, 179, 0.5);
	}
	.btn + .btn {
		margin-left: 10px;
	}
}

.panelists-list-progress-wrapper {
	display: flex;
	justify-content: center;
}

.panelists-list-progress-label {
	font-size: 14px;
	line-height: 14px;
	color: $black;
}

.panelists-list-progress-bar-wrapper {
	padding: 2px 20px;
}

.panelists-list-progress-bar {
	width: 345px;
	height: 10px;
	background-color: $pale-grey;
	border-radius: 5px;
	margin: 0 27px;
}

.panelists-list-progress-close {
	font-size: 12px;
	color: $blue;
}

.panelists-list-from-file-row {
	display: flex;
	&.in-header {
		margin-top: 66px;
	}
}

.panelists-list-pagination {
	padding: 1em;
	display: flex;
	justify-content: center;
}

.panelists-list-from-file-row-cell,
.panelists-list-from-own-data-cell {
	@include fake-checkbox-mixin();
	& {
		display: flex;
		padding-bottom: 29px;
		border-bottom: 2px solid $pale-grey;
	}
	&.ordinal-number,
	span,
	&.mail,
	&.panelist,
	&.age,
	&.gender {
		font-size: 12px;
		color: $black;
		line-height: 20px;
	}
	&.mail,
	&.panelist,
	&.age,
	&.gender {
		font-weight: bold;
		padding-left: 30px;
	}
	&.checkbox-cell:not(.panelists-list-from-own-data-cell) {
		flex: 1;
		justify-content: center;
		span {
			margin-right: 6px;
		}
	}
	&.ordinal-number {
		flex: 1;
		justify-content: center;
		padding-right: 20px;
	}
	&.mail:not(.panelists-list-from-own-data-cell) {
		flex: 7;
	}
	&.remove {
		flex: 3;
		&:hover {
			cursor: pointer;
		}
		span {
			font-size: 12px;
			color: $blue;
			font-weight: 500;
			position: relative;
			top: 2px;
			margin-left: 20px;
		}
		img {
			width: 20px;
			height: 20px;
		}
	}
	&.with-data {
		padding-top: 28px;
		padding-bottom: 28px;
		&.mail {
			flex: 10;
			font-weight: normal;
		}
		.fake-checkbox-container {
			margin-left: 20px;
		}
		&.ordinal-number,
		&.mail {
			font-size: 14px;
		}
	}
}

.panelists-list-from-own-data-cell {
	&.checkbox-cell span {
		margin-right: 6px;
	}
	&.checkbox-cell,
	&.ordinal-number,
	&.mail,
	&.age,
	&.gender,
	&.panelist {
		justify-content: center;
	}

	&.checkbox-cell,
	&.ordinal-number {
		flex: 3;
	}
	&.ordinal-number {
		padding-right: 0;
	}
	&.panelist {
		flex: 6;
	}
	&.mail {
		flex: 8;
	}
	&.age {
		flex: 4;
	}
	&.gender {
		flex: 8;
	}
	&.empty-cell {
		flex: 20;
	}
	&.data-cell {
		padding: 28px 0;
		font-size: 14px;
		&.mail,
		&.age,
		&.gender {
			font-weight: normal;
		}
		.fake-checkbox-container {
			margin-left: 18px;
		}
	}
}

.panelists-list-from-own-data-row {
	display: flex;
	justify-content: flex-start;
	&.filters {
		margin-top: 66px;
		margin-bottom: 66px;
	}
	.btn-wrapper {
		display: inline-flex;
		position: relative;
		& + .btn-wrapper {
			margin-left: 50px;
		}
	}
	.btn {
		& {
			width: initial;
			//padding: 10px 17px;
			//padding: 0;
			line-height: initial;
			color: $black;
			background-color: $white;
			font-weight: 700;
			font-size: 14px;
			//border: 1px solid $grey;
			min-width: 200px;
			border-color: $transparent; //added
		}
		&:active {
			box-shadow: none;
		}
		&:hover {
			border-color: $pale-blue;
		}
		span {
			& {
				display: flex;
				border: 1px solid $grey;
				height: 100%;
				width: 100%;
				padding: 10px 17px;
				border-radius: 4px;
				justify-content: center;
				align-items: center;
			}
			&.active {
				border: 1px solid $blue;
				color: $blue;
				&:hover {
					border-color: $blue;
				}
			}
			&:hover {
				border-color: $pale-blue;
			}
		}
	}
}

.simple-filter-wrapper {
	padding: 30px;
	box-shadow: $box-shadow-simple-filter;
	background-color: $white;
	position: absolute;
	top: calc(100% + 12px);
	z-index: 5;
	img {
		width: 16px;
		height: 16px;
		position: absolute;
		top: 34px;
		right: 30px;
		&:hover {
			cursor: pointer;
		}
	}
}

.simple-filter-title {
	font-size: 16px;
	color: $black;
	font-weight: 500;
	margin-bottom: 32px;
}

.simple-filter-checkbox-wrapper {
	@include fake-checkbox-mixin();
	display: flex;
	flex-flow: column;
	min-width: 390px;
	border-bottom: 2px solid $grey;
	padding-bottom: 56px;
	&.inline-content {
		flex-flow: row;
		.fake-checkbox-container + .fake-checkbox-container {
			margin-left: 47px;
		}
	}
	.fake-checkbox-label {
		font-size: 14px;
		position: relative;
		top: -6px;
	}
}

.simple-filter-accept {
	color: $blue;
	font-size: 12px;
	font-weight: 500;
	text-align: right;
	margin-top: 30px;
	span:hover {
		cursor: pointer;
	}
}

.panelists-list-no-results {
	text-align: center;
	margin-top: 50px;
}
