.information-title {
	width: 400px;
	font-size: 28px;
	font-weight: 300;
	margin-bottom: 10px;
}

.information-subtitle {
	width: 400px;
	font-size: 14px;
	font-weight: 500;
}

.form {
	display: flex;
	flex-direction: column;
	button {
		margin: 20px 0 25px 110px;
	}
	&-data {
		display: initial;
		width: 400px;
		font-size: 12px;
		line-height: 20px;
		text-align: justify;
		overflow: hidden;
		overflow-wrap: break-word;
		max-height: 80px;
		height: auto;
		button {
			margin: 0;
			background-color: transparent;
			color: $blue;
		}
		&-max {
			max-height: 460px;
			@include transition(0.5s, max-height, ease-in);
		}
	}
}

.form-forgotten {
	cursor: pointer;
}

.form-input-container {
	display: flex;
	height: 42px;
	position: relative;
	margin-bottom: 30px;
	&.error input {
		border: 1px solid #f00;
	}
	&:first-of-type {
		margin-top: 45px;
	}
	input {
		padding-left: 40px;
		width: 400px;
		border: 1px solid $grey;
		border-radius: 4px;
		&::placeholder {
			font-family: Rubik;
			font-size: 14px;
		}
	}
}

.login_link {
	cursor: pointer;
	color: $blue;
}
.form-input-error {
	position: absolute;
	bottom: -16px;
	font-size: 12px;
	font-weight: normal;
	color: #ff0000;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.form-input-icon {
	position: absolute;
	top: 0;
	bottom: 0;
	margin-top: auto;
	margin-bottom: auto;
	left: 10px;
	width: 20px;
	height: 20px;
}

.form-checkbox {
	width: 400px;
	margin-bottom: 15px;
}

.form-checkbox input[type='checkbox'] {
	display: none;
}

.form-checkbox label {
	font-size: 12px;
	padding-left: 30px;
	line-height: 20px;
	position: relative;
	display: flex;
	cursor: pointer;
	div {
		margin-top: -4px;
		text-align: justify;
	}
}

.form-checkbox label:before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 20px;
	height: 20px;
	box-sizing: border-box;
	border: 1px solid black;
	border-radius: 2px;
	background: white;
}

.form-checkbox.error label:before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 20px;
	height: 20px;
	box-sizing: border-box;
	border: 1px solid #f00;
	border-radius: 2px;
	background: white;
}

.form-checkbox label:after {
	content: '';
	position: absolute;
	top: 3px;
	left: 3px;
	width: 15px;
	height: 13px;
	background: transparent url('../../../assets/surveys/questions/check.svg')
		no-repeat center center;
	background-size: 15px 13px;
	transform: scale(0);
	transition: transform 0.25s ease;
}

.form-checkbox input[type='checkbox']:checked + label:after {
	transform: scale(1);
}

.form-login {
	display: flex;
	align-items: center;
	width: 400px;
	p {
		white-space: nowrap;
		font-size: 12px;
		font-weight: 500;
		color: $blue;
		margin-bottom: 15px;
	}
}

.form-forgotten-box {
	display: flex;
	justify-content: flex-end;
	width: 400px;
	p {
		white-space: nowrap;
		font-size: 12px;
		font-weight: 500;
		color: $blue;
		margin-top: -20px;
	}
}

.information-account {
	font-size: 12px;
	font-weight: 500;
	width: 400px;
	text-align: center;
}

.information-login {
	color: $blue;
	cursor: pointer;
}
.reset-success-link{
	@extend .login_link;
	@extend .information-account;
	margin-top: 5px;
}
.form-name-list {
	position: absolute;
	z-index: 200;
	width: 100%;
	margin-top: 42px;
	border: 1px solid $grey;
	&-item {
		background-color: #fff;
		height: 23px;
		padding-left: 10px;
		font-size: 14px;
		font-weight: 300;
		display: flex;
		align-items: center;
		&:hover {
			background-color: $pale-blue;
			cursor: pointer;
		}
	}
}
