.page-container {
	display: flex;
	flex-direction: column;
	position: relative;
}

.add-survey {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 50px 0;
	width: 100%;
	border-bottom: 1px solid $grey;
	&:first-child {
		border: none;
	}
}

.add-survey-title {
	font-size: 16px;
	font-weight: 500;
	padding-bottom: 30px;
}
.add-survey.titles {
	background-color: $white;
	border: 1px solid $pale-grey;
	margin-bottom: 46px;
	margin-top: 50px;
	box-shadow: -7px 14px 16px 0 rgba(230, 237, 239, 0.54);
	.btn.btn-blue {
		margin-right: 40px;
	}
}

.add-survey-autosave-info,
.edit-survey-autosave-info {
	position: fixed;
	bottom: 0;
	right: 0;
	background-color: $white;
	padding: 20px;
	opacity: 0.5;
}
