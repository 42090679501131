.info-modal-mask {
	position: fixed;
	z-index: 1000;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: white;
	opacity: 0.8;
}

.info-modal-container {
	position: fixed;
	z-index: 10000;
	width: 60%;
	height: 230px;
	margin: auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border: 1px solid #ceced4;
	border-radius: 4px;
	box-shadow: 0 11px 38px 0 rgba(87, 87, 87, 0.13);
	background-color: white;
}

.info-modal-container-info {
	width: 100%;
	padding-bottom: 6px;
	text-align: justify;
	&-item {
		width: 100%;
		font-weight: 300;
	}
	&-end {
		padding-bottom: 16px;
	}
}

.info-modal-content {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	height: 100%;
	padding: 40px 50px;
}

.info-modal-container-texts {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.info-modal-container-title {
	text-align: center;
	font-size: 26px;
	font-weight: 300;
	margin-bottom: 30px;
}

.info-modal-container-image {
	width: 45%;
	display: flex;
	justify-content: flex-end;
}
