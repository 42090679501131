.interviewers-surveys-container-labels {
	display: flex;
	align-items: center;
	border-bottom: 1px solid #f1f1f5;
	font-size: 12px;
	font-weight: 700;
	height: 76px;
}

.interviewer-surveys-container-content-elements {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border-bottom: 1px solid #f1f1f5;
	font-size: 14px;
	height: 76px;
}

.interviewer-list-panel-content-info {
	display: flex;
	justify-content: center;
	margin: 30px 0;
}

.interviewer-list-panel-content-elements-survey-name {
	font-weight: 300;
	flex: 1 1 30%;
	margin-left: 36px;
}

.interviewer-list-panel-content-elements-start {
	flex: 1;
	flex: 1 1 15%;
}

.interviewer-list-panel-content-elements-type {
	font-weight: 500;
	flex: 1 1 10%;
}

.interviewer-list-panel-content-elements-number {
	flex: 1 1 10%;
}

.interviewer-list-panel-content-elements-end {
	flex: 1 1 15%;
}

.interviewer-list-panel-content-elements-status {
	font-weight: 500;
	flex: 1 1 15%;
	color: $blue;
}

.interviewer-surveys-container-content {
	.interviewer-surveys-container-content-elements {
		box-shadow: none;
	}
}

.interviewer-list-panel-content-elements-survey-name.bold,
.interviewer-list-panel-content-elements-start.bold,
.interviewer-list-panel-content-elements-type.bold,
.interviewer-list-panel-content-elements-number.bold,
.interviewer-list-panel-content-elements-end.bold,
.interviewer-list-panel-content-elements-status.bold {
	font-weight: 700;
	text-transform: uppercase;
	color: $black;
}

.interviewers-surveys-pagination {
	margin: 55px 0 70px;
}
