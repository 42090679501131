.completion-limit {
	position: relative;
	top: 0;
	padding: 0 30px 40px 30px;

	.details-detail-points-title {
		margin: 30px 0;
	}

	.limit-conditions {
		// display: flex;
		// flex-direction: row;
		.limit-form-conditions-item{
			
		}
		// & > p {
		// 	display: flex;
		// 	padding-top: 13px;
		// 	font-size: 16px;
		// 	width: 220px;
		// 	text-align: left;
		// }
	}
}
