.contests-details-container {
	height: 100%;
}

.contests-details-status-title {
	font-size: 26px;
	font-weight: 500;
	margin-bottom: 45px;
}

.contests-details-status-container {
	display: flex;
	flex: 1 1 70%;
	border: 1px solid $grey;
	background-color: $white;
	padding: 19px 0;
	label:after {
		top: 24px;
		left: 4px;
		width: 12px;
		height: 12px;
	}
}

.contests-details-details-title {
	font-size: 26px;
	font-weight: 500;
	margin: 51px 0 45px;
}

.contests-details-details-container {
	background-color: #f6f6fa;
	padding: 24px 20px 30px;
	margin-bottom: 136px;
}

.contests-details-details-name-container {
	background-color: $white;
	border: 1px solid $graphite;
	margin-bottom: 24px;
	padding: 30px 24px;
}

.contests-details-details-text {
	font-size: 14px;
	font-weight: 500;
	padding-bottom: 7px;
}

.contests-details-details-name {
	font-size: 28px;
	font-weight: 300;
}

.contests-details-details-content-container {
	background-color: $white;
	border: 1px solid $graphite;
	padding: 54px 30px;
	.add-survey-radio {
		padding: 37px 0 0;
	}
	.add-survey-radio label {
		display: block;
	}
	.add-survey-radio input[type='radio']:checked + label:before {
		border-color: $black;
	}
	.add-survey-radio label:after {
		background-color: $black;
	}
}

.contests-details-details-question {
	font-size: 18px;
	font-weight: 500;
	padding-bottom: 17px;
}

.contests-details-details-description {
	font-size: 14px;
	span {
		color: $text-grey;
	}
}
