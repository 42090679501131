.contests-container {
	height: 100%;
}

.contests-container-contests-title {
	font-size: 26px;
	font-weight: 500;
	padding: 63px 0 40px;
}

.contests-card {
	display: flex;
	justify-content: space-between;
	background-color: $white;
	height: 110px;
	width: 50%;
	box-shadow: 0 2px 11px 0 $sidebar-gradient-gray-right;
}

.contests-card-content {
	margin: 32px 0 0 38px;
	h2 {
		font-size: 28px;
		font-weight: 300;
		margin-bottom: 10px;
	}
	p {
		font-size: 14px;
	}
}

.contests-card-icon {
	width: 25px;
	height: 19px;
	margin: 61px 42px 0 0;
	filter: invert(48%) sepia(33%) saturate(7216%) hue-rotate(325deg)
		brightness(97%) contrast(99%);
}

.contests-list-title-button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 63px 0 40px;
}

.contests-container-list-title {
	font-size: 26px;
	font-weight: 500;
}

.contests-list-header {
	display: flex;
	justify-content: space-between;
	padding-bottom: 30px;
	border-bottom: 1px solid $pale-grey;
}

.contests-list-header-name,
.contests-list-header-status {
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
	width: 100px;
}

.contests-list-header-name {
	margin-left: 36px;
}

.contests-list-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid $pale-grey;
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
	position: relative;
	&:last-child {
		margin-bottom: 100px;
	}
	.surveys-list-edit-delete {
		background-color: $background-edge;
		font-size: 14px;
		font-weight: 300;
		width: 20%;
	}
	&:hover {
		box-shadow: 0 2px 6px 0 rgba(230, 230, 230, 0.5);
		border-left: 1px solid #e4e4e5;
		border-right: 1px solid #e4e4e5;
	}
}

.contests-list-content:hover .surveys-list-edit-delete {
	z-index: 100;
}

.contests-list-content-name {
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 300;
	margin-left: 36px;
	width: 80%;
	height: 78px;
	text-decoration: none;
	color: $black;
	cursor: pointer;
}

.contests-list-content-status {
	font-size: 14px;
	font-weight: 500;
	width: 100px;
}

// Panelists

.contests-container {
	height: 100%;
}

.contests-header-panelists-empty {
	margin-top: 100px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.contests-header-panelists-greeting {
	font-size: 42px;
	font-weight: 500;
	padding-top: 50px;
	margin-bottom: 50px;
}

.contests-header-panelists-infobox {
	width: 45%;
	display: flex;
	flex-direction: row;
	position: relative;
	padding: 30px 60px;
	padding-right: 15px;
	box-shadow: -7px 14px 16px 0 rgba(230, 237, 239, 0.54);
	background-color: #fff;
}

.contests-header-panelists-infobox-left {
	h3 {
		font-size: 18px;
		font-weight: 500;
	}
	h2 {
		font-size: 48px;
		font-weight: 500;
		color: $blue;
		margin: 20px 0;
	}
	p {
		font-size: 12px;
		font-weight: normal;
	}
}

.contests-header-panelists-infobox-right {
	display: flex;
	flex-direction: column;
	width: 50%;
	position: relative;
	img {
		padding-top: 35px;
	}
}

.contests-header-panelists-infobox-information {
	margin-top: 50px;
	margin-bottom: 30px;
	h3 {
		font-size: 18px;
		font-weight: 500;
	}
	p {
		font-size: 12px;
		font-weight: normal;
	}
}

.contests-header-panelists-tiles-columns {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.contests-header-panelists-tiles {
	display: flex;
	flex-direction: column;
	width: 45%;
}

.contests-header-panelists-tile {
	height: 60px;
	display: flex;
	flex-direction: row;
	border: 1px solid $grey;
	background-color: #fff;
	margin-bottom: 30px;
	color: #000;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
	text-decoration: none;
	div {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	& .red {
		font-weight: 500;
		color: $red;
	}
	img {
		width: 40px;
		height: 40px;
		margin-right: 10px;
	}
	&.inactive {
		cursor: default;
	}
}

.contest-header-panelist {
	margin-bottom: 40px;
	font-size: 26px;
	font-weight: 500;
}

.contest-buttons {
	margin-top: 40px;
	margin-bottom: 70px;
	display: flex;
	justify-content: flex-end;
	height: 100%;
	align-items: flex-end;
	button:first-child {
		margin-right: 10px;
	}
}

.contest-content-form-checkbox {
	width: 400px;
	margin-bottom: 15px;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 40px 0;
	font-size: 12px;
	p {
		margin-left: 5px;
		color: $blue;
		cursor: pointer;
	}
}

.contest-content-form-checkbox input[type='checkbox'] {
	display: none;
}

.contest-content-form-checkbox label {
	font-size: 12px;
	padding-left: 30px;
	line-height: 20px;
	position: relative;
	display: flex;
}

.contest-content-form-checkbox label:before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	margin-top: auto;
	margin-bottom: auto;
	left: 0;
	width: 20px;
	height: 20px;
	box-sizing: border-box;
	border: 1px solid black;
	border-radius: 2px;
	background: white;
}
.contest-content-form-checkbox label:after {
	content: '';
	position: absolute;
	top: 3px;
	left: 3px;
	width: 15px;
	height: 13px;
	background: transparent url('../../../assets/surveys/questions/check.svg')
		no-repeat center center;
	background-size: 15px 13px;
	transform: scale(0);
	transition: transform 0.25s ease;
}

.contest-content-form-checkbox input[type='checkbox']:checked + label:after {
	transform: scale(1);
}
