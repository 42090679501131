.surveys-list-main {
	padding-bottom: 70px;
	font-size: 14px;
}

.surveys-list-list {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid $pale-grey;
	border-right: 1px solid transparent;
	border-left: 1px solid transparent;
	position: relative;
	font-size: 14px;
}

.surveys-list-list:not(.labels):hover {
	background-color: $white;
	border-right-color: $pale-grey;
	border-left-color: $pale-grey;
}

.surveys-list-list.labels p {
	padding: 10px;
}

.surveys-list-list:hover .surveys-list-edit-delete {
	z-index: 100;
}

.survey-list-mask {
	background-color: white;
	z-index: 1000;
	height: 100%;
	width: 150px;
	opacity: 0.7;
	border: $darker-blue;
	position: absolute;
	right: 30px;
}
