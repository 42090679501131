.companies-content-account {
    display: flex;
    flex-direction: column;
}

.companies-content-account-main {
    display: flex;
}

.companies-content-account-title {

    font-size: 26px;
    font-weight: 500;
}

.companies-content-account-inputs {
    p {
        font-size: 16px;
        font-weight: 500;
        margin: 30px 0 30px;
    }
    input {
        width: 325px;
        height: 42px;
        border: 1px solid $grey;
        padding-left: 26px;
        margin-bottom: 40px;
    }

    input:disabled{
        border: solid 1px #e4e4e5;
        background-color: #ffffff;
    }
    
}


