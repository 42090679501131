.table {
    width: 100%;
    border-collapse: collapse;
    .head {
            height: 78px;
            border-bottom: solid 1px $pale-grey;
            text-transform: uppercase;
    
        }
    
        .headerCell {
            text-align: left;
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
            padding-left: 25px;
        }
    
        .row {
            font-size: 16px;
            border: solid 1px transparent;
            border-bottom: solid 1px $pale-grey;
            // @include transition(0.25s, box-shadow, ease-out);
    
            &:hover {
                border: solid 1px $pale-grey;
                // box-shadow: $box-shadow-list;
                z-index: 2;
            }
        }
    
        .cell {
            height: 78px;
            padding-left: 25px;
        }
}

