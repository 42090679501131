.companies-list-main {
    padding-bottom: 70px;
    font-size: 14px;
}

.companies-list-pagination {
    display: flex;
    justify-content: center;
}

.companies-list-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $pale-grey;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
    position: relative;
    font-size: 14px;
}

.companies-list-list:not(.labels):hover {
    background-color: $white;
    border-right-color: $pale-grey;
    border-left-color: $pale-grey;
}

.companies-list-list.labels p {
    padding: 10px;
}

.companies-list-list:hover .companies-list-edit-delete {
    z-index: 100;
}

.companies-list-mask {
    background-color: white;
    z-index: 1000;
    height: 100%;
    width: 150px;
    opacity: 0.7;
    border: $darker-blue;
    position: absolute;
    right: 30px;
}
