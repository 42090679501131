.summary-status-container {
	display: flex;
	border: solid 1px $grey;
	height: 100px;
	margin-top: 45px;
}

.summary-radio-buttons-container {
	display: flex;
	flex: 1 1 70%;
}

.summary-status-info {
	display: flex;
	align-items: center;
	font-size: 14px;
}

.summary-status-icon {
	margin: 0 20px 0 40px;
	filter: invert(48%) sepia(33%) saturate(7216%) hue-rotate(325deg)
		brightness(97%) contrast(99%);
}

.summary-radio {
	display: flex;
	align-items: center;
	padding-left: 50px;
	position: relative;
}

.summary-radio input[type='radio'] {
	display: none;
}

.summary-radio label {
	font-size: 12px;
	font-weight: 700;
	padding: 0 0 0 30px;
	line-height: 20px;
	position: relative;
	display: flex;
	cursor: pointer;
}

.summary-radio label:before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	margin-top: auto;
	margin-bottom: auto;
	left: 0;
	width: 20px;
	height: 20px;
	box-sizing: border-box;
	border: 1px solid black;
	border-radius: 50%;
	background: white;
}
.summary-radio label:after {
	content: '';
	position: absolute;
	top: 25px;
	left: 5px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: $blue;
	transform: scale(0);
	transition: transform 0.25s ease;
}

.summary-radio input[type='radio']:checked + label:before {
	border-color: $blue;
}

.summary-radio input[type='radio']:checked + label:after {
	transform: scale(1);
}

.summary-status-radio-label {
	padding: 20px 0;
}

.summary-status-mask {
	position: unset;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0.4;
	background-color: #fcfdff;
	&.active {
		position: absolute;
	}
}

.summary-send-button-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1 1 30%;
	border-left: 1px solid $grey;
	margin: 10px 0;
}

.test {
	&.invisible {
		display: none;
	}
}

.summary-link-box {
	position: relative;
	display: flex;
	padding: 10px 0;
	border: 1px solid $grey;
	height: 100px;
}
.summary-download-link {
	img {
		width: 50px;
		margin-bottom: 20px;
	}
}

.summary-link-coped {
	transform: scale(0);
	position: absolute;
	border-radius: 5px;
	top: 5px;
	right: 0;
	margin: 0 30px;
	padding: 2px 7px;
	font-size: 12px;
	background-color: $blue;
	color: #fff;
	&-show {
		transform: scale(1);
		transition: transform 0.25s ease;
	}
}

.link-box-wrapper {
	display: flex;
	border-right: 1px solid $grey;
	justify-content: center;
	align-items: center;
	width: 85%;
	padding: 0 30px;
	word-break: break-word;
	&.capi {
		visibility: hidden;
	}
	span {
		font-size: 14px;
		margin-right: 25px;
	}
	/* input {
        height: 40px;
        width: 60%;
        border: 1px solid $grey;
        border-radius: 3px;
        padding-left: 26px;
    } */
	textarea {
		font-size: 12px;
		width: 60%;
		height: 84px;
		border: 1px solid $grey;
		border-radius: 3px;
		padding: 4px 16px;
		resize: none;
		overflow: hidden;
	}
	textarea:focus {
		outline: none;
	}
}

.button-box-container {
	display: flex;
	flex: 2;
}

.button-box {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	&-copy {
		max-height: 40px;
		cursor: pointer;
	}
	&-copy:hover {
		filter: invert(51%) sepia(61%) saturate(1804%) hue-rotate(160deg)
			brightness(96%) contrast(96%);
	}
}

.button-box:first-child {
	border-right: 1px solid #e4e4e5;
}

.summary-header {
	font-size: 26px;
	font-weight: 500;
	color: $lighter-black;
	margin-top: 46px;
	margin-bottom: 50px;
}

.summary-box-container {
	display: flex;
	justify-content: space-between;
}

.summary-box-section {
	height: 300px;
	width: calc(50% - 20px);
	border-radius: 4px;
	box-shadow: 0 2px 11px 0 $sidebar-gradient-gray-right;
}

.summary-box-item {
	margin: 0 35px;
	height: 33%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid $grey;
}

.summary-box-item:last-child {
	border-bottom: none;
}

.summary-box-icon {
	margin-right: 20px;
}

.summary-box-item-name {
	font-size: 14px;
}

.summary-box-item-label {
	display: flex;
	align-items: center;
	&.cawi {
		opacity: 0.4;
	}
}

.summary-box-item-info {
	font-size: 18px;
	font-weight: 500;
	display: flex;
	justify-content: flex-end;
	text-align: right;
}

.results-header {
	font-size: 26px;
	font-weight: 500;
	color: $lighter-black;
	margin-top: 46px;
	margin-bottom: 50px;
	&-marginBottomHide {
		margin-bottom: 10px;
	}
}

.summary-interviewers-container {
	display: flex;
	justify-content: space-between;
	border: 1px solid $grey;
	padding: 35px 40px;
	img {
		filter: invert(48%) sepia(33%) saturate(7216%) hue-rotate(325deg)
			brightness(97%) contrast(99%);
	}
}

.summary-results-cawi {
	.surveys-list-name-container {
		flex: 1 1 50%;
	}
	.surveys-list-name-container.bold {
		justify-content: flex-start;
	}
	.surveys-list-name-area {
		height: 80px;
		display: flex;
		align-items: center;
		cursor: pointer;
	}
	.surveys-list-sort {
		flex: 1 1 50%;
	}

}
.summary-retry-mail-button-container {
	display: flex;
	width: 100%;
	justify-content: space-around;
	button {
		margin: 0 5px;
	}
}

.send_panel_container {
	justify-content: space-around;
	.summary-select_language {
		margin-right: 20px;
		width: 170px;
		.summary-option_label_language {
			display: flex;
			align-items: center;
			& img {
				width: 30px;
				margin-right: 10px;
			}
		}
	}
}

.surveys-list-sort-status {
	font-weight: 500;
	&.blue {
		color: $blue;
	}
}

.surveys-list-sort-points .bolder {
	font-weight: 500;
}

.summary-results-cawi {
	.surveys-list-sort-points.bold,
	.surveys-list-sort-status.bold {
		position: relative;
		border: 1px solid white;
	}

	.surveys-list-sort-points.bold:hover,
	.surveys-list-sort-status.bold:hover {
		background-color: $white;
		border-color: $grey;
		border-radius: 2px;
		cursor: pointer;
	}

	.surveys-list-sort-points.bold:hover:after,
	.surveys-list-sort-status.bold:hover:after {
		content: '';
		width: 8px;
		height: 8px;
		border-bottom: 2px solid $red;
		border-right: 2px solid $red;
		transform: rotate(45deg);
		position: absolute;
		top: 8px;
		right: 25px;
	}

	.surveys-list-sort-points.bold.selected,
	.surveys-list-sort-status.bold.selected {
		background-color: $white;
		border-color: $grey;
		border-radius: 2px;
	}

	.surveys-list-sort-points.bold.selected:after,
	.surveys-list-sort-status.bold.selected:after {
		content: '';
		width: 8px;
		height: 8px;
		border-bottom: 2px solid $red;
		border-right: 2px solid $red;
		transform: rotate(45deg);
		position: absolute;
		top: 8px;
		right: 25px;
	}

	.surveys-list-sort-points.bold.selected.arrow-transformation:after,
	.surveys-list-sort-status.bold.selected.arrow-transformation:after {
		transform: rotate(225deg);
		top: 12px;
	}
}

.summary-results-capi {
	.surveys-list-name-container,
	.surveys-list-sort {
		display: flex;
		justify-content: space-around;
		align-items: center;
		flex: unset;
		height: 80px;
		width: 30%;
		&.bold {
			justify-content: space-around;
		}
		.surveys-list-name.bold {
			flex: 25%;
			padding-left: 0;
			text-align: center;
		}
		.surveys-list-sort-mail.bold {
			flex: 25%;
			text-transform: uppercase;
			text-align: center;
			font-size: 12px;
			font-weight: 700;
		}
		.surveys-list-name {
			width: 50%;
			padding-left: 50px;
			&:hover {
				cursor: pointer;
			}
		}
		.surveys-list-name-area {
			height: 80px;
			display: flex;
			align-items: center;
			cursor: pointer;
		}
		.surveys-list-sort-mail {
			width: 50%;
			padding-left: 50px;
			& span {
				white-space: nowrap;
			}
		}
	}
	.surveys-list-sort-amount.bold,
	.surveys-list-sort-status.bold {
		position: relative;
		border: 1px solid white;
	}

	.surveys-list-sort-amount.bold:hover,
	.surveys-list-sort-status.bold:hover {
		background-color: $white;
		border-color: $grey;
		border-radius: 2px;
		cursor: pointer;
	}

	.surveys-list-sort-amount.bold:hover:after,
	.surveys-list-sort-status.bold:hover:after {
		content: '';
		width: 8px;
		height: 8px;
		border-bottom: 2px solid $red;
		border-right: 2px solid $red;
		transform: rotate(45deg);
		position: absolute;
		top: 8px;
		right: 25px;
	}

	.surveys-list-sort-amount.bold.selected,
	.surveys-list-sort-status.bold.selected {
		background-color: $white;
		border-color: $grey;
		border-radius: 2px;
	}

	.surveys-list-sort-amount.bold.selected:after,
	.surveys-list-sort-status.bold.selected:after {
		content: '';
		width: 8px;
		height: 8px;
		border-bottom: 2px solid $red;
		border-right: 2px solid $red;
		transform: rotate(45deg);
		position: absolute;
		top: 8px;
		right: 25px;
	}

	.surveys-list-sort-amount.bold.selected.arrow-transformation:after,
	.surveys-list-sort-status.bold.selected.arrow-transformation:after {
		transform: rotate(225deg);
		top: 12px;
	}
}

.surveys-list-name-bullet {
	display: block;
	width: 14px;
	height: 14px;
	border-radius: 7px;
	background-color: $red;
	margin-right: 9px;
}

.surveys-list-name-spacer {
	margin-right: 18px;
}

.surveys-statistics-main-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 10px;
	&-empty {
		margin-top: 20px;
	}
	.panel-statistics {
		display: flex;
		width: 100%;
		height: 40px;
		justify-content: space-between;
		margin-top: 10px;
		margin-bottom: 20px;
		.to-left-button {
			margin-left: auto;
			margin-right: 10px;
		}
	}
	.tabs-statistics {
		width: 100%;
	}
}

.surveys-statistics-page {
	&-container {
		width: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		background-color: $pale-grey;

		padding-bottom: 0;
	}
	&-title {
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 20px;
	}
}

.surveys-statistics-element {
	&-container {
		width: 100%;
		padding: 60px 20px 40px 20px;
		margin-bottom: 40px;
		background-color: $white;
	}
	&-question {
		font-size: 18px;
		font-weight: 500;
	}
	&-description {
		display: flex;
		flex-direction: row;
		font-size: 14px;
		font-weight: 400;
		margin-top: 17px;
		padding-bottom: 32px;
		&-comment {
			margin-left: 5px;
			color: $text-grey;
		}
	}
	&-scale {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		padding-top: 40px;
		padding-bottom: 40px;
		border-top: 2px solid $pale-grey;
		border-bottom: 2px solid $pale-grey;
		&-box {
			display: flex;
			flex-direction: column;
			height: 100px;
			width: 100%;
			border: 1px solid $pale-grey;
			border-left: none;
			justify-content: center;
			align-items: center;
			position: relative;
			&:first-child {
				border-left: 1px solid $pale-grey;
			}
			&-answers {
				position: absolute;
				top: 30px;
				font-size: 18px;
				font-weight: 500;
			}
			&-labels {
				display: flex;
				justify-content: center;
				align-items: center;
				border: 2px solid $pale-grey;
				border-radius: 100%;
				width: 27px;
				height: 27px;
				position: absolute;
				bottom: 11px;
			}
		}
	}
	&-open {
		padding-top: 20px;
		padding-bottom: 20px;
		border-top: 2px solid $pale-grey;
		border-bottom: 2px solid $pale-grey;

		&-single {
			display: flex;
			flex-wrap: wrap;
		}

		&-answer {
			padding-bottom: 16px;
			max-width: 100%;
			&-single {
				padding-bottom: 8px;
				padding-right: 8px;
				max-width: 100%;
			}
		}

		&-text {
			font-size: 12px;
			margin-right: 5px;
		}
	}
	&-ranking {
		width: 50%;
		border-top: 2px solid $pale-grey;
		&-answer {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			border-bottom: 2px solid $pale-grey;
			padding-top: 15px;
			padding-bottom: 15px;
			max-width: 100%;
		}
	}
	&-choice {
		width: 50%;
		border-top: 2px solid $pale-grey;
		&-answer {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			border-bottom: 2px solid $pale-grey;
			padding-top: 15px;
			padding-bottom: 15px;
			&-value {
				display: flex;
				justify-content: center;
				width: 40px;
				font-weight: bold;
			}
		}
	}
	&-matrix {
		display: flex;
		flex-direction: row;
		border-top: 2px solid $pale-grey;
		border-bottom: 2px solid $pale-grey;
		padding: 60px 55px;
		&-first {
			display: flex;
			align-items: center;
			padding-left: 15px;
			height: 120px;
			font-size: 12px;
			font-weight: 500;
			border-right: 1px solid $grey;
			border-bottom: 1px solid $grey;
			&:not(:first-child) {
				border-left: 1px solid $grey;
				background: $pale-grey;
			}
		}
		&-labels {
			flex: 7;
		}
		&-column {
			flex: 5;
		}
		&-head {
			display: flex;
			align-items: center;
			padding-left: 15px;
			border-right: 1px solid $grey;
			border-top: 1px solid $grey;
			border-bottom: 1px solid $grey;
			background: $pale-grey;
			height: 120px;
			font-size: 12px;
			font-weight: 500;
		}
		&-item {
			display: flex;
			align-items: center;
			justify-content: center;
			border-right: 1px solid $grey;
			border-bottom: 1px solid $grey;
			height: 120px;
			font-size: 18px;
			font-weight: bold;
		}
	}
}

.tabs-statistics {
	.react-tabs__tab-list {
		display: flex;
		list-style: none;
		cursor: pointer;
		border-bottom: 2px solid $pale-grey;

		.react-tabs__tab {
			padding: 15px 30px;
			background-color: #fff;
			border: 2px solid $pale-grey;
			border-bottom: none;
			font-weight: 500;
			font-size: 15px;

			&--selected {
				background-color: $pale-grey;
				outline: none;
			}
		}
	}
	
}
.tabs-statistics__panel-container {
	width: 100%;
	padding: 20px;
	background-color: $pale-grey;
}
.statistics__empty {
	padding: 20px;
	background-color: #fff;
}
.surveys-end-element-statistics {
	list-style: none;

	.surveys-end-element-statistics__surveys-end-element-statistics-title {
		font-size: 14px;
		font-weight: 500;
	}
	.surveys-end-element-statistics__surveys-end-element-statistics-header {
		display: flex;
		align-items: center;
		gap: 15px;
		border-bottom: 1px solid $pale-grey;
		padding-bottom: 17px;
	}

	.surveys-end-element-statistics__case {
		display: flex;
		align-items: center;
	}
	.surveys-end-element-statistics-case {
		display: flex;
		gap: 10px;
	}

	.surveys-end-element-statistics__question-case {
		margin-bottom: 27px;
	}

	.surveys-end-element-statistics__case-title {
		font-size: 16px;
		width: 250px;
	}
	.surveys-end-element-statistics__fake-input {
		height: 40px;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		border: 1px solid $pale-grey;
		font-size: 14px;
		font-weight: 300;
		padding: 8px 12px;
		flex-grow: 1;
	}

	.surveys-end-element-statistics__element {
		display: flex;
		flex-direction: column;
		background-color: #fff;
		padding: 20px;
		gap: 27px;
		margin-bottom: 20px;
	}
	.surveys-end-element-statistics__operator {
		height: 55px;
	}
	.surveys-end-element-statistics__counter {
		width: 150px;
		flex-grow: 0;
		text-align: center;
		font-weight: 700;

		&.exceeded {
			color: $red !important;
			border-color: $red;
			font-weight: 700;
		}
		&.reached {
			color: $green;
			border-color: $green;
			font-weight: 700;
		}
	}
}
