.surveys-list-edit-delete {
	position: absolute;
	top: 0;
	bottom: 0;
	margin-top: auto;
	margin-bottom: auto;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 40%;
	background-color: white;
	z-index: -1;
}

.surveys-list-edit,
.surveys-list-delete {
	text-align: center;
	margin-right: 50px;
	cursor: pointer;
}

.surveys-list-empty {
	text-align: center;
	padding-top: 50px;
}

.surveys-list-pages {
	display: flex;
	padding-bottom: 30px;
}

.surveys-list-pages div {
	padding: 0 10px;
}

.filter-checkbox-container {
	display: flex;
	align-items: center;
}

.status-filter {
	font-size: 13px;
	padding-left: 20px;
	font-weight: 700;
}

.status-filter-container {
	margin-top: 10px;
	display: flex;
	width: 10%;
	min-width: 200px;
	text-wrap: avoid;
	align-items: center;
}

.type-filter {
	font-size: 13px;
	padding-left: 30px;
	font-weight: 700;
}

.type-filter-container {
	margin-top: 10px;
	display: flex;
	width: auto;
	align-items: center;
	justify-content: center;
}

.filter-radio input[type='checkbox'] {
	display: none;
}

.filter-radio-label {
	font-size: 14px;
	font-weight: 400;
	padding-right: 20px;
	line-height: 20px;
	position: relative;
	display: flex;
	align-items: center;
	cursor: pointer;
	white-space: nowrap;
}
.filter-radio-checkbox {
	position: relative;
	margin-right: 2px;
	width: 16px;
	height: 16px;
	box-sizing: border-box;
	border: 1px solid black;
	background: white;
}
.filter-radio-checkbox:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $blue;
	transform: scale(0);
	transition: transform 0.25s ease;
}
.filter-radio
	input[type='checkbox']:checked
	+ .filter-radio-label
	.filter-radio-checkbox {
	border-color: $blue;
}
.filter-radio
	input[type='checkbox']:checked
	+ .filter-radio-label
	.filter-radio-checkbox:after {
	transform: scale(0.7);
}

.filter-radio-checkbox:before {
	// content: '';
	// position: absolute;
	// top: 0;
	// bottom: 0;
	// margin-top: auto;
	// margin-bottom: auto;
	// left: 0;
	// width: 16px;
	// height: 16px;
	// box-sizing: border-box;
	// border: 1px solid black;
	// background: white;
}
// .filter-radio-checkbox:before {
//     content: '';
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     margin-top: auto;
//     margin-bottom: auto;
//     left: 0;
//     width: 16px;
//     height: 16px;
//     box-sizing: border-box;
//     border: 1px solid black;
//     background: white;
// }
// .filter-radio-checkbox:after {
//     content: '';
//     position: absolute;
//     top: 7px;
//     left: 3px;
//     width: 10px;
//     height: 10px;
//     background-color: $blue;
//     transform: scale(0);
//     transition: transform 0.25s ease;
// }

// .filter-radio input[type="checkbox"]:checked + label:before {
//     border-color: $blue;
// }

// .filter-radio input[type="checkbox"]:checked + label:after {
//     transform: scale(1);
// }

// .filter-status-radio-label {
//     padding: 2px 0;
// }
