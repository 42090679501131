.settings-content-work-header {
	margin: 35px 0;
	font-size: 16px;
	font-weight: 500;
	&:first-child {
		margin-top: 0;
	}
}

.settings-content-work-container {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 40px;
}

.settings-content-work-body {
	width: 33%;
	margin-bottom: 20px;
	font-size: 14px;
	position: relative;
	&.family-marital {
		width: 50%;
	}
	&.family-list {
		width: 20%;
	}
	input {
		display: none;
	}
	label {
		margin-left: 25px;
		line-height: 22px;
		padding-right: 25px;
	}
	label:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 20px;
		height: 20px;
		box-sizing: border-box;
		border: 1px solid $black;
		border-radius: 50%;
		background: white;
	}
	label:after {
		content: '';
		position: absolute;
		top: 4px;
		left: 4px;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background-color: $blue;
		transform: scale(0);
		transition: transform 0.25s ease;
	}
	input[type='radio']:checked + label:before {
		border-color: $blue;
	}

	input[type='radio']:checked + label:after {
		transform: scale(1);
	}
}

.settings-content-more {
	cursor: pointer;
	color: $blue;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 500;
	margin: 30px 0;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		width: 8px;
		height: 8px;
		border-top: 2px solid $blue;
		border-right: 2px solid $blue;
		transform: rotate(135deg);
		margin-left: 10px;
		top: -2px;
	}
	&.home {
		margin-top: 10px;
		margin-bottom: 40px;
	}
}
