.element-summary-questions {
	// display: flex;
	position: relative;
	flex-direction: column;
	align-items: flex-start;
	.choice-details-item {
		width: 100%;
		display: flex;
		margin-bottom: 30px;
		label {
			text-align: left;
			width: 100px;
			margin-right: 10px;
			word-wrap: break-all;
		}
	}
}

.element-summary-questions-title {
	font-size: 18px;
	font-weight: 500;
	padding: 94px 0 30px 0;
	text-align: left;
}

.element-summary-questions-title-additional {
	display: none;
	&.minimal {
		display: inline-block;
		color: $text-grey;
		padding-left: 10px;
	}
}

.element-summary-questions-subtitle {
	font-size: 14px;
	padding-bottom: 45px;
	text-align: left;
	&.info {
		color: $text-grey;
	}
}

.element-summary-questions-answers-container {
	display: flex;
	align-items: center;
	padding-bottom: 30px;
}

.choice-details-checkbox {
	&.black {
		border-color: $black;
	}
}

.choice-details-input-another {
	font-size: 14px;
	padding-left: 13px;
	border: 1px solid $text-grey;
	color: $text-grey;
	height: 43px;
	vertical-align: middle;
	display: flex;
	align-items: center;
	width: 100%;
}

.summary-single-matrix-wrapper {
	margin-bottom: 80px;
	.details-questions-matrix-header {
		width: 100%;
		.details-questions-matrix-label {
			padding-top: 0;
		}
	}
	.details-questions-matrix-label {
		border: 1px solid $grey;
		background-color: $pale-grey;
		margin-top: 0;
		&.empty {
			margin: 0;
			width: 150%;
			border-color: transparent;
			background-color: transparent;
		}
	}
	.details-questions-matrix-label-box {
		height: 100%;
		border: none;
	}
	.details-questions-matrix-checkbox {
		height: 100%;
		margin: 0;
	}
	.details-questions-matrix-answers {
		.add-survey-radio {
			padding: 0;
			label {
				width: 100%;
				height: 100%;
				padding: 0;
				&:before {
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
				&:after {
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					margin: auto;
				}
			}
		}
		.add-survey-checkbox {
			padding: 0;
			label {
				width: 100%;
				height: 100%;
				padding: 0;
				&:before {
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
				&:after {
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					margin: auto;
				}
			}
		}
	}
}

.summary-scale-question-wrapper {
	& {
		width: 100%;
		height: 100%;
		position: relative;
		padding: 30px 0;
	}
	.summary-scale-wrapper {
		display: flex;
		flex-flow: row;
	}
	.slider-wrapper {
		flex: 9;
		height: 28px;
		border-radius: 14px;
		padding-left: 34px;
		padding-right: 34px;
	}
	.slider-wrapper + .summary-scale-description {
		text-align: left;
	}
	.summary-scale-description {
		flex: 3;
		height: 100%;
		line-height: 28px;
		top: 0;
		color: $text-grey;
		font-size: 14px;
		text-align: right;
	}
	
	
}

.rangeslider__handle-label {
	user-select: none;
	top: -46px;
	position: absolute;
	left: -12px;
	font-size: 16px;
	font-weight: bold;
	width: 58px;
	height: 39.2px;
	border-radius: 16.5px;
	background-color: #f6f6fa;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: default;
}

.rangeslider__handle:after {
	display: none;
}

.rangeslider-horizontal .rangeslider__fill {
	background-color: $pale-blue;
	border-radius: 28px;
	position: absolute;
	padding-left: 25px;
}

.rangeslider-horizontal {
	height: 28px;
	border-radius: 28px;
	top: -21px;
	position: relative;
}

.rangeslider,
.rangeslider .rangeslider__fill {
	box-shadow: none;
}

.rangeslider .rangeslider__handle {
	cursor: default;
	outline: none;
	box-shadow: none;
}

.active .rangeslider .rangeslider__handle {
	cursor: pointer;
}

.rangeslider-horizontal .rangeslider__handle {
	width: 34px;
	height: 34px;
}

// MEDIA
.element-summary-media-container {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 30px;
}

// RANKING
.element-summary-ranking-question {
	width: 100%;
	border: 1px solid $text-grey;
	padding: 12px 0;
	margin-bottom: 40px;
	font-size: 14px;
	text-align: center;
	word-break: break-word;
}
