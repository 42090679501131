.settings-content-interests-header {
	margin: 35px 0;
	font-size: 16px;
	font-weight: 500;
	&:first-child {
		margin-top: 0;
	}
}

.settings-content-interests-container {
	display: flex;
	flex-wrap: wrap;
}

.settings-content-interests-body {
	width: 33%;
	margin-bottom: 20px;
	font-size: 14px;
	position: relative;
	height: 44px;
	input {
		display: none;
	}
	label {
		word-break: break-word;
		margin-left: 25px;
		line-height: 22px;
		padding-right: 25px;
	}
	label:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 20px;
		height: 20px;
		box-sizing: border-box;
		border: 1px solid $black;
		background: white;
	}
	label:after {
		content: '';
		position: absolute;
		top: 3px;
		left: 3px;
		width: 15px;
		height: 13px;
		background: transparent url('../../../../assets/surveys/questions/check.svg')
			no-repeat center center;
		background-size: 15px 13px;
		transform: scale(0);
		transition: transform 0.25s ease;
	}
	input[type='checkbox']:checked + label:before {
		border-color: $blue;
	}

	input[type='checkbox']:checked + label:after {
		transform: scale(1);
	}
}
