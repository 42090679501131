.choice-details-container {
	padding-bottom: 40px;
	border-bottom: 1px solid $grey;
}

.choice-details-title {
	font-size: 16px;
	font-weight: 500;
	text-align: left;
	margin: 30px 0;
}

.choice-details-item {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
}

.choice-details-item:last-child {
	// to make it work
	margin-bottom: 40px;
}

.choice-details-checkbox-container {
	display: flex;
}

.choice-details-checkbox {
	width: 20px;
	height: 20px;
	border: 1px solid $grey;
	margin-right: 30px;
	&.single {
		border-radius: 50%;
	}
	&.multiple {
		border-radius: 0;
	}
	&.open {
		display: none;
	}
}

.choice-details-others .choice-details-checkbox {
	border-color: $black;
}

.choice-details-text {
	flex: 1 1 82%;
	height: 43px;
	width: 100%;
	padding-left: 27px;
}

.choice-details-circles {
	flex: 1 1 10%;
}

.choice-details-circle {
	width: 20px;
	height: 20px;
	cursor: pointer;
	&:first-child {
		margin-right: 20px;
	}
	&.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
}

.choice-details-others {
	& {
		display: flex;
		align-items: center;
		padding-top: 34px;
		border-top: 1px solid $grey;
	}
	&.choice-multiple {
		padding: 0 0 40px;
		border-top: none;
		width: 100%;
	}
	&.another-supported {
		label:before {
			border: 1px solid $blue;
		}
	}
	input[type='checkbox'] {
		display: none;
	}
	label {
		padding: 0 0 0 40px;
		font-size: 14px;
		color: black;
		position: relative;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
	}
	label:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		margin-top: auto;
		margin-bottom: auto;
		left: 0;
		width: 20px;
		height: 20px;
		box-sizing: border-box;
		border: 1px solid black;
		background: white;
	}
	label:after {
		content: '';
		position: absolute;
		top: 2px;
		left: 3px;
		width: 15px;
		height: 13px;
		background: transparent url('../../../../assets/surveys/questions/check.svg')
			no-repeat center center;
		background-size: 15px 13px;
		transform: scale(0);
		transition: transform 0.25s ease;
	}
	input[type='checkbox']:checked + label:after {
		transform: scale(1);
	}
}

.element-summary-questions {
	.choice-details-others {
		display: block;
		text-align: left;
		&.another-supported {
			display: flex;
		}
	}
}

.choice-details-others-single {
	& {
		display: flex;
		align-items: center;
		padding-top: 34px;
		border-top: 1px solid $grey;
	}
	&.choice-multiple {
		padding: 0 0 40px;
		border-top: none;
		width: 100%;
	}
	&.another-supported {
		label:before {
			border: 1px solid $pale-blue;
		}
		label:after {
			background-color: $blue;
		}
	}
	input[type='radio'] {
		display: none;
	}
	label {
		padding: 0 0 0 40px;
		font-size: 14px;
		color: black;
		position: relative;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
	}
	label:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		margin-top: auto;
		margin-bottom: auto;
		left: 0;
		width: 20px;
		height: 20px;
		box-sizing: border-box;
		border: 1px solid black;
		border-radius: 50%;
		background: white;
	}
	label:after {
		content: '';
		position: absolute;
		left: 4px;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background-color: black;
		transform: scale(0);
		transition: transform 0.25s ease;
	}
	input[type='radio']:checked + label:after {
		transform: scale(1);
	}
}

.element-summary-questions {
	.choice-details-others-single {
		display: block;
		text-align: left;
		&.another-supported {
			display: flex;
		}
	}
}

.choice-details-label {
	font-size: 14px;
}

.choice-details-label-another {
	font-size: 14px;
	margin-right: 50px;
}

.choice-details-number {
	padding-left: 13px;
	margin-left: 48px;
	height: 43px;
	&.choice {
		display: none;
	}
	&.choice-multiple {
		width: 100%;
	}
}
@media screen and (max-width: 767px) {
	.choice-details-number {
		margin:0;
		
	}
  }