.sidebar {
	z-index: 150;
	position: fixed;
	width: 100px;
	height: 100%;
	background-color: $dark-blue;
}

.sidebar-extended {
	width: 200px;
}

.sidebar-logo {
	height: 200px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	padding-top: 40px;
	&-small {
		height: 22px;
	}
}

.sidebar-arrow {
	height: 20px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-right: 30px;
}

.sidebar-arrow:hover {
	cursor: pointer;
}

.sidebar-extend {
	width: 8px;
	height: 8px;
	border-bottom: 2px solid $red;
	border-right: 2px solid $red;
	transform: rotate(315deg);
}

.sidebar-extend-extended {
	transform: rotate(135deg);
}

.sidebar-navigation {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 30px;
}

.sidebar-element {
	display: flex;
	align-items: center;
	height: 43px;
	margin: 13px 0;
	cursor: pointer;
	text-decoration: none;
	justify-content: flex-start;
	width: 80%;
	border-radius: 1px;
}

.sidebar-highlited {
	background-color: $darker-blue;
}

.sidebar-navigation-items {
	display: flex;
	margin-left: 27px;
}

.sidebar-highlited-img {
	filter: invert(48%) sepia(33%) saturate(7216%) hue-rotate(325deg)
		brightness(97%) contrast(99%);
}

.sidebar-navigation-item-name {
	line-height: 23px;
	margin-left: 20px;
	font-size: 12px;
	font-weight: 500;
	color: $white;
}

@media only screen and (max-height: 700px) {
	.sidebar-logo {
		height: 130px;
	}
}
