@keyframes spin {
	from {
		transform: rotate(0);
	}

	to {
		transform: rotate(359deg);
	}
}
.csv-report {
	.csv-report__header {
		display: flex;
		align-items: center;
		margin: 40px 0 30px;
	}
	.csv-report__refresh-button {
		margin-left: 20px;

		svg {
			height: 25px;
		}
	}
	.loading {
		animation: spin 0.7s linear infinite;
	}
	.csv-report__title {
		font-size: 26px;
		font-weight: 500;
		line-height: 0.92;
	}
	.csv-report__spinner {
		height: 20px;
	}
	.csv-report__info-file-row {
		display: flex;
		padding: 20px 0;
		align-items: center;
		min-height: 22px;
	}
	.csv-report__info-file-row-name {
		font-weight: 500;
		width: 140px;
		margin-right: 20px;
	}
	.csv-report__button {
		margin: 0 10px;
		&:first-child {
			margin: 0 10px 0 0;
		}
		&:last-child {
			margin: 0 0 0 10px;
		}
	}
}
