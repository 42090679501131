.settings-content-empty {
	display: flex;
	justify-content: center;
	margin: 30px 0;
}

.settings-content-users-title {
	font-size: 26px;
	font-weight: 500;
}

.settings-content-users-add {
	font-size: 16px;
	font-weight: 500;
	padding: 30px 0 20px;
}

.settings-content-users-instruction {
	width: 65%;
	font-size: 14px;
	letter-spacing: 0.7px;
	padding-bottom: 20px;
}

.settings-content-users-input {
	display: flex;
	margin-bottom: 25px;
	position: relative;
	input {
		width: 74%;
		height: 42px;
		border: 1px solid $grey;
		padding-left: 26px;
		margin-right: 30px;
	}
	&.error input {
		border: 1px solid #f00;
	}
}

.settings-content-users-users {
	font-size: 16px;
	font-weight: 500;
	padding: 30px 0;
	border-top: 1px solid #e4e4e5;
}

.settings-content-users-labels {
	display: flex;
	padding-bottom: 30px;
	border-bottom: 1px solid #e4e4e5;
}

.settings-content-users-mail {
	width: 70%;
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
}

.settings-content-users-user {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 30px 0;
	border-bottom: 1px solid #e4e4e5;
}

.settings-content-users-active {
	font-size: 12px;
	color: $blue;
}

.settings-content-users-deactivated {
	font-size: 12px;
	color: $black;
}

.settings-content-users-address {
	width: 70%;
	font-size: 14px;
}

.settings-content-users-delete-img {
	margin-right: 30px;
	cursor: pointer;
}

.settings-content-users-input-message {
	position: absolute;
	bottom: -18px;
	font-size: 12px;
	font-weight: normal;
	&.error {
		color: #ff0000;
	}
}

.settings-content-users-toggle-switch {
	position: relative;
	display: inline-block;
	width: 39px;
	height: 20px;
	input {
		display: none;
	}
}

.settings-content-users-toggle-slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 34px;
	&:before {
		position: absolute;
		content: '';
		height: 18px;
		width: 18px;
		left: 2px;
		bottom: 1px;
		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
		border-radius: 50%;
	}
}

input:checked + .settings-content-users-toggle-slider {
	background-color: $blue;
}

input:checked + .settings-content-users-toggle-slider:before {
	-webkit-transform: translateX(17px);
	-ms-transform: translateX(17px);
	transform: translateX(17px);
}
