.panelist-interviewer-summary-back-container {
	display: flex;
	align-items: center;
	padding: 70px 0;
	width: 100px;
	cursor: pointer;
	p {
		padding-left: 18px;
	}
}

.panelist-interviewer-summary-survey-name {
	padding-top: 70px;
	font-size: 28px;
	font-weight: 300;
}

.panelist-interviewer-summary-button-container {
	display: flex;
	justify-content: center;
	padding: 45px 0;
}

// Interviewers

.interviewer-back-button-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 70px;
	width: 100px;
	text-decoration: none;
	color: #000;
	cursor: pointer;
	p {
		padding-left: 18px;
	}
}
