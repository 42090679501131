.details-detail-container {
	position: relative;
	top: 0;
	padding: 0 30px 40px 30px;
}

.details-detail-editor {
	display: flex;
	flex-flow: column;
	text-align: left;
	padding: 30px 0;
	border-bottom: 1px solid $grey;

}

.details-detail-editor-title {
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 32px;
}

.details-detail-wysiwyg {
	height: 207px;
}

.details-detail-wysiwyg-infobox {
	font-size: 10px;
	font-weight: 300;
	color: $lighter-black;
	opacity: 0.5;
	margin-top: 10px;
	&.red {
		color: $red;
		font-weight: 600;
	}
}

.details-detail-points-title {
	margin: 30px 0;
}

.details-detail-points-selector {
	width: 500px;
	height: 40px;
	border-radius: 2px;
	font-size: 14px;
	border: 1px solid $text-grey;
}

.mce-branding.mce-widget.mce-label.mce-flow-layout-item.mce-last {
	display: none;
}

.mce-flow-layout-item.mce-resizehandle {
	display: none;
}
