.add-survey-calendar-container {
	position: absolute;
	background: $white;
	z-index: 999;
	flex: 1;
	height: 564px;
	width: 450px;
	border: 1px solid $grey;
	border-radius: 5px;
	top: 825px;
	right: 50%;
	transform: translateX(50%);
}

.add-survey-calendar-header {
	position: relative;
	height: 60px;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	font-weight: 500;
	border-bottom: 2px solid $grey;
	margin: auto;
	width: 95%;
}

.add-survey-calendar-header-close {
	position: absolute;
	margin: auto;
	top: 0;
	bottom: 0;
	right: 13px;
	height: 20px;
	width: 22px;
}
.add-survey-calendar-header-close:hover {
	cursor: pointer;
}

.add-survey-calendar-header-close:before,
.add-survey-calendar-header-close:after {
	content: '';
	position: absolute;
	left: 15px;
	height: 20px;
	width: 2px;
	background-color: #333;
}

.add-survey-calendar-header-close:before {
	transform: rotate(45deg);
}

.add-survey-calendar-header-close:after {
	transform: rotate(-45deg);
}

.add-survey-calendar-nav-prev {
	width: 15px;
	height: 15px;
	border-bottom: 1px solid #808080;
	border-right: 1px solid #808080;
	transform: rotate(135deg);
	position: absolute;
	margin: auto;
	top: 98px;
	left: 89px;
}

.add-survey-calendar-nav-next {
	width: 15px;
	height: 15px;
	border-bottom: 1px solid #808080;
	border-right: 1px solid #808080;
	transform: rotate(315deg);
	position: absolute;
	margin: auto;
	top: 98px;
	right: 89px;
}

.add-survey-calendar-legend {
	display: flex;
	align-items: center;
	margin-top: 80px;
}

.add-survey-calendar-legend-labels {
	display: flex;
	align-items: center;
}

.add-survey-calendar-day-current,
.add-survey-calendar-day-selected {
	position: relative;
	margin-left: 40px;
}

.add-survey-calendar-day-current:before,
.add-survey-calendar-day-selected:before {
	content: '';
	position: absolute;
	border-radius: 10px;
	width: 8px;
	height: 8px;
	left: -17px;
	top: 5px;
}

.add-survey-calendar-day-current:before {
	background-image: linear-gradient(to bottom right, #e07fde, orange);
}

.add-survey-calendar-day-selected:before {
	background-image: linear-gradient(to bottom right, blue, violet);
}

.add-survey-calendar-day-accept {
	position: absolute;
	left: 220px;
}

/* CALENDAR LIBRARY - DO NOT CHANGE, CLASSNAMES COME FROM PLUGIN! */
.react-calendar {
	padding: 0 75px;
}

.react-calendar__navigation {
	display: flex;
	height: 90px;
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
	display: none;
}

.react-calendar__navigation__next-button,
.react-calendar__navigation__prev-button {
	outline: none;
	width: 20px;
	background-color: $white;
	color: #808080;
	border: none;
	display: inline-block;
	font-size: 38px;
}

.react-calendar__tile--now {
	color: $white;
	background-image: linear-gradient(to bottom right, #e07fde, orange);
}

.react-calendar__tile--active {
	color: $white !important;
	background-image: linear-gradient(to bottom right, blue, violet);
}
.react-calendar__month-view__weekdays {
	margin-bottom: 24px;
	font-size: 14px;
}

.react-calendar__month-view__weekdays__weekday {
	text-transform: capitalize;
	text-align: center;
	color: #808080;
}

.react-calendar__navigation__label {
	background-color: $white;
	border: none;
	color: $black;
	text-transform: capitalize;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 18px;
	font-weight: 600;
}

.react-calendar__month-view__days__day--neighboringMonth {
	color: #808080;
}

.react-calendar__month-view__days__day {
	outline: none;
	height: 42px;
	background-color: $white;
	border: 4px solid $white;
	border-radius: 100%;
}
