.survey-logic-tooltip-container {
	height: 95px;
	box-shadow: -7px 14px 16px 0 rgba(230, 237, 239, 0.54);
	border: 1px solid $pale-grey;
	border-top: none;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.survey-logic-tooltip-text {
	text-align: left;
	max-width: calc(100% - 200px);
}

.survey-logic-tooltip-infosign {
	width: 20px;
	height: 20px;
	// background-image: url('../assets/surveys/logic/information.svg');
	margin: 0 25px;
}

.survey-logic-tooltip-close {
	width: 20px;
	height: 20px;
	margin-left: 80px;
	margin-right: 30px;
	background: url('../../../../assets/surveys/logic/close.svg') no-repeat;
	&:hover {
		cursor: pointer;
	}
}

.survey-logic-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 50px 0;
	& p {
		font-size: 28px;
	}
	& button {
		width: 200px;
		height: 40px;
		border: 1px solid $pale-grey;
		border-radius: 2px;
		background-color: #ffffff;
		font-size: 12px;
		font-weight: bold;
	}
	&-container {
		display: flex;
		flex-flow: row;
		align-items: center;
	}
	&-infosign {
		width: 20px;
		height: 20px;
		margin-left: 25px;
		// background-image: url('../assets/surveys/logic/information.svg');
	}
}

.survey-logic-condition-head {
	display: flex;
	flex-direction: row;
	align-items: center;
	white-space: nowrap;
	margin-bottom: 50px;
}

.survey-logic-condition-title {
	text-align: left;
	width: auto;
	margin-right: 30px;
	font-size: 16px;
	font-weight: 500;
	& span {
		color: $red;
	}
}

.survey-logic-condition-line {
	border: 1px solid $blue;
	border-top: none;
	width: 100%;
	height: 1px;
}

.survey-logic-condition-delete {
	color: $blue;
	margin-left: 30px;
	display: flex;
	flex-direction: row;
	position: relative;
	cursor: pointer;
	&:before {
		content: '';
		position: absolute;
		width: 16px;
		height: 16px;
		left: -20px;
		background-image: url('../../../../assets/surveys/logic/bin.svg');
		background-repeat: no-repeat;
		background-size: contain;
	}
}

.survey-logic-condition-questions {
	display: flex;
	.questionSelect {
		padding-left: 25px;
	}
}

.survey-logic-condition-answers {
	margin: 35px 0;
}

.survey-logic-condition-answers-circle {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 1px solid $black;
	margin-right: 10px;
}

.survey-logic-condition-answers.input {
	& > div {
		padding-left: 25px;
		display: flex;
		align-items: center;
		width: 100%;
		height: 43px;
		border-radius: 2px;
		border: 1px solid $grey;
		position: relative;
	}
}

.survey-logic-condition-answers.list {
	display: flex;
}

.survey-logic-condition-answers.range {
	display: flex;

	
}
.survey-logic-condition-answers-select-container{
	display: flex;
	flex-grow: 1;
	gap:10px;
	.survey-logic-condition-answer-select{
		flex-grow: 1;
	}
}
.survey-logic-condition-answers-limit-quantity{
	padding: 10px;
	max-width: 150px;
	&.error{
		border: 1px solid $red;
	}
}

.survey-logic-condition-question {
	display: flex;
	flex-direction: row;
	& > p {
		display: flex;
		align-items: center;
		font-size: 16px;
		width: 220px;
	}
	& > div {
		padding-left: 25px;
		display: flex;
		align-items: center;
		width: 100%;
		height: 43px;
		border: 1px solid $pale-grey;
		border-top: none;
		&:hover:not(.empty) {
			background-color: $grey;
			cursor: pointer;
		}
	}
}

.survey-logic-condition-questions,
.survey-logic-condition-answers {
	display: flex;
	flex-direction: row;
	.survey-logic-condition-questions-select,
	.survey-logic-condition-answer-select {
		flex-grow: 1;
	}
	& > p {
		display: flex;
		flex-shrink: 0;
		padding-top: 13px;
		font-size: 16px;
		width: 220px;
		text-align: left;
	}
}
.survey-logic-condition-conditions-title{
	margin-bottom: 25px;
}
.survey-logic-condition-conditions {
	height: 100px;
	background-color: $pale-grey;
	border: 1px solid $grey;
	border-left: none;
	border-right: none;
	margin: 35px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	font-weight: 500;
	&-checkboxes {
		
		font-size: 14px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 180px;
	}
}

.number-selector {
	position: absolute;
	width: calc(100% - 59px);
	right: 0;
	top: 43px;
	z-index: 100;
	& p {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 26px;
		border: 1px solid $grey;
		background-color: $white;
	}
}

.survey-logic-condition-mask-container {
	position: relative;
	& label {
		padding-left: 40px;
		position: relative;
		&:before {
			content: '';
			position: absolute;
			top: -2px;
			left: 0;
			width: 20px;
			height: 20px;
			box-sizing: border-box;
			border: 1px solid $black;
			border-radius: 50%;
			background: white;
		}
		&:after {
			content: '';
			position: absolute;
			top: 2px;
			left: 4px;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			background-color: $blue;
			transform: scale(0);
			transition: transform 0.25s ease;
		}
	}
	& input {
		display: none;
	}
	& input[type='radio']:checked + label:before {
		border-color: $pale-blue;
	}
	& input[type='radio']:checked + label:after {
		transform: scale(1);
	}
}

.survey-logic-condition-mask {
	background-color: $pale-grey;
	width: 65px;
	height: 30px;
	position: absolute;
	z-index: 1;
	top: -7px;
	opacity: 0.8;
}

.content-mask {
	z-index: 150;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #00000080;
	position: fixed;
	top: 0;
	left: 0;
	
}

.content-popup-background {
	z-index: 420;
	width: 70vw;
	max-height: 100%;
	overflow: auto;
	background-color: white;
	padding: 30px;
	position: relative;
}

.survey-logic-popup {
	&-close {
		width: 20px;
		height: 20px;
		position: absolute;
		right: 30px;
		background: url('../../../../assets/surveys/logic/close.svg') no-repeat;
		&:hover {
			cursor: pointer;
		}
	}
	&-title {
		display: flex;
		font-size: 28px;
		font-weight: 300;
		margin-top: 40px;
	}
	&-description {
		display: flex;
		font-size: 16px;
		margin-bottom: 45px;
	}
	&-line {
		border: 2px solid $pale-grey;
		border-top: none;
	}
	&-list-item {
		display: flex;
		position: relative;
		padding: 15px;
		text-align: left;
		&:hover {
			cursor: pointer;
			background-color: $pale-grey;
		}
		&.highlighted {
			background-color: $grey;
			&:hover {
				background-color: $grey;
			}
		}
	}
	&-empty-list-item {
		text-align: left;
	}
	&-list-container {
		display: flex;
		margin-top: 50px;
		margin-bottom: 70px;
		& > p {
			display: flex;
			width: 20%;
			font-size: 16px;
			font-weight: 500;
			text-align: left;
			padding-top: 30px;
		}
		& > div {
			display: flex;
			flex-flow: column;
			width: 80%;
			margin-left: 20px;
			padding: 15px;
			border: 2px solid $pale-grey;
		}
	}
	&-buttons {
		display: flex;
		margin-top: 55px;
		justify-content: flex-end;
		gap:10px;
	}
}
