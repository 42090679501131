.surveys-dashboard-container {
	.contests-card {
		margin-bottom: 60px;
	}
	.surveys-list-panel {
		justify-content: flex-end;
	}
}

.surveys-dashboard-title {
	font-size: 26px;
	font-weight: 500;
	padding-bottom: 10px;
}

.surveys-dashboard-subtitle {
	font-size: 16px;
	font-weight: normal;
	padding-bottom: 40px;
	border-bottom: 1px solid $grey;
}

.surveys-dashboard-list {
	font-size: 26px;
	font-weight: 500;
	padding: 40px 0;
}

.surveys-dashboard-amount {
	font-size: 18px;
	font-weight: 500;
	&.red {
		color: $red;
	}
}

.summary-box-section {
	&.surveys-dashboard {
		height: 110px;
		padding: 26px 41px 32px 42px;
	}
}

.summary-box-item-top {
	p {
		font-size: 18px;
		font-weight: 500;
	}
}

.summary-box-item-bottom {
	& {
		display: flex;
		justify-content: space-between;
		margin-top: 15px;
	}
	span {
		font-size: 14px;
	}
	img {
		width: 20px;
		height: 13px;
	}
}

.summary-results-container-row,
.summary-results-container-cell {
	display: flex;
	align-items: center;
	height: 73px;
	border-bottom: 1px solid $pale-grey;
}

.summary-results-container-row {
	&.bold {
		font-size: 12px;
		font-weight: 700;
		text-transform: uppercase;
	}
}

.summary-results-container-cell {
	&.checkbox {
		flex: 0 1 10%;
		justify-content: center;
		&.all {
			span {
				font-weight: 400;
				text-transform: initial;
			}
		}
	}
	&.number {
		flex: 0 1 10%;
		padding-left: 5px;
		&.all {
			padding: 0;
		}
	}
	&.interviewer {
		flex: 0 1 20%;
		&.bold {
			font-weight: 500;
		}
	}
	&.mail,
	&.amount {
		flex: 0 1 30%;
	}
	&.amount {
		input {
			height: 40px;
			width: 80px;
			padding-left: 15px;
			padding-right: 15px;
			margin: 0 10px;
			text-align: center;
			border: 1px solid #e7e7e8;
		}
	}
	.add-survey-radio,
	.add-survey-checkbox {
		padding: 0;
	}
}

.surveys-list-main-container {
	display: flex;
	flex-direction: column;
	align-items: center;

	.surveys-list-main-container-empty{
		margin-top: 20px;
	}
}

.survey-list-main-button {
	display: flex;
	justify-content: center;
	margin-bottom: 40px;
	button {
		margin: 5px;
	}
}

.survey-dashboard-bottom {
	& {
		display: flex;
		justify-content: center;
		position: relative;
		margin-bottom: 60px;
	}
}

// CHECKBOX
.summary-results-container-cell.checkbox input[type='checkbox'] {
	display: none;
}

.summary-results-container-cell.checkbox label {
	font-size: 12px;
	padding-right: 26px;
	line-height: 20px;
	position: relative;
	display: flex;
	cursor: pointer;
}

.summary-results-container-cell.checkbox label:before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	margin-top: auto;
	margin-bottom: auto;
	left: 0;
	width: 20px;
	height: 20px;
	box-sizing: border-box;
	border: 1px solid black;
	border-radius: 2px;
	background: white;
}

.summary-results-container-cell.checkbox.blue label:before {
	border-color: $blue;
}

.summary-results-container-cell.checkbox label:after {
	content: '';
	position: absolute;
	top: -6px;
	left: 3px;
	width: 15px;
	height: 13px;
	background: transparent url('../../../../assets/surveys/questions/check.svg')
		no-repeat center center;
	background-size: 15px 13px;
	transform: scale(0);
	transition: transform 0.25s ease;
}

.summary-results-container-cell.checkbox
	input[type='checkbox']:checked
	+ label:after {
	transform: scale(1);
}
