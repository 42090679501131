.settings-container {
	height: 100%;
}

.settings-title {
	font-size: 26px;
	font-weight: 500;
	padding: 63px 0 10px;
}

.settings-subtitle {
	font-size: 16px;
	padding-bottom: 40px;
}

.settings-content-container {
	display: flex;
	background-color: #f6f6fa;
	padding: 30px 30px 30px 0;
	margin-bottom: 80px;
	position: relative;
}

.settings-content-options {
	width: 20%;
}

.settings-content-option {
	font-size: 16px;
	font-weight: 500;
	padding-left: 30px;
	margin-bottom: 42px;
	border-left: 2px solid transparent;
	cursor: pointer;
	&:first-child {
		margin-top: 26px;
	}
	&.highlighted {
		color: $blue;
		border-left-color: $blue;
	}
}

.settings-content-changes {
	width: 80%;
	border: 1px solid #e4e4e5;
	background-color: $white;
	padding: 40px 30px;
}
