.spinner-container {
	margin: auto;
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;
}

.spinner {
	border: 4px solid $dark-blue;
	border-left: 4px solid;
	animation: load 700ms infinite linear;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	outline: none;
}

@keyframes load {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
