.pagination {
	display: flex;
	justify-content: center;
	list-style: none;
}

.pagination > li > a {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 35px;
	height: 35px;
	text-decoration: none;
	font-weight: 500;
	color: $black;
	background-color: $white;
	border: 1px solid $white;
	border-radius: 50%;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
	z-index: 3;
	background-color: $blue;
	border-color: $blue;
	cursor: default;
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
	cursor: not-allowed;
}
