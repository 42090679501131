.notFound-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: #dfdfdf;

	.popup {
		display: flex;
		flex-direction: column;

		justify-content: center;
		align-items: center;
		width: 800px;
		height: 420px;
		background-color: #fff;
		border-radius: 4px;
		box-shadow: 0 11px 38px 0 rgba(87, 87, 87, 0.13);
		border: solid 1px #ceced4;
		padding: 50px 100px;

		&-content {
			display: flex;
			align-items: center;
			width: 100%;
			height: 100%;

			&-msg {
				color: #34395d;
				h1 {
					font-size: 52px;
				}
				h2 {
					font-size: 22px;
				}
				margin: auto;
				text-align: center;
			}
		}
	}
}
