.panelists-container {
	padding-top: 63px;
	.panelists-pagination {
		margin-top: 50px;
	}
}
.panelists-table-header-row,
.panelists-table-data-row {
	display: flex;
	align-items: center;
	height: 77px;
	border-bottom: 1px solid $grey;
}
.panelists-table-header-row {
	text-transform: uppercase;
}
.panelists-table-header-cell {
	& {
		font-size: 12px;
		color: $black;
		font-weight: bold;
	}
	&.number-spacer {
		flex: 1;
	}
	&.panelist {
		flex: 2;
	}
	&.mail {
		flex: 2;
	}
	&.delete-spacer {
		flex: 5;
		&.base {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			margin-right: 30px;
			color: $blue;
			cursor: pointer;
			img {
				margin-right: 18px;
			}
		}
	}
}
.panelists-table-data-row {
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
	&:hover {
		& {
			box-shadow: 0 2px 6px 0 rgba(230, 230, 230, 0.5);
			border-left: 1px solid $grey;
			border-right: 1px solid $grey;
		}
		.delete-btn-wrapper {
			display: block;
			font-weight: 400;
			margin-right: 30px;
		}
	}
}
.delete-btn-wrapper {
	display: none;
	> div {
		line-height: initial;
		display: flex;
		flex-flow: column wrap;
		text-align: center;
		span {
			color: $blue;
			margin-top: 8px;
		}
	}
}
.panelists-table-data-cell {
	& {
		font-size: 14px;
		color: $black;
		font-weight: 500;
	}
	&.number {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&.panelist {
		height: 100%;
		flex: 2;
		display: flex;
		align-items: center;
		&:hover {
			cursor: pointer;
		}
		&.manage {
			cursor: auto;
		}
	}
	&.mail {
		flex: 2;
		font-weight: normal;
	}
	&.delete {
		flex: 5;
		justify-content: flex-end;
		align-items: center;
		display: flex;
		cursor: pointer;
	}
}

.panelists-list-empty {
	text-align: center;
	padding-top: 50px;
}

// MANAGEMENT
.panelists-management-pagination {
	margin-top: 50px;
}
