.add-survey-logo-upload {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $white;
	border: 2px dashed lightgray;
	width: 55%;
}

.add-survey-logo-input {
	display: none;
}

.add-survey-logo-label {
	width: 100%;
	margin: 15px 0;
	cursor: pointer;
}

.add-survey-logo-label-text {
	font-size: 12px;
	font-weight: 500;
	color: rgba(40, 45, 50, 0.5);
	text-align: center;
	cursor: pointer;
}

.add-survey-logo-image-container {
	display: flex;
	align-items: center;
}

.add-survey-logo-image-field {
	position: relative;
	border: 2px solid $blue;
	width: 180px;
	height: 154px;
	display: flex;
	align-items: center;
	justify-content: center;
	& img {
		max-width: 100%;
		max-height: 100%;
	}
}

.add-survey-logo-delete-button {
	position: absolute;
	top: 5px;
	right: 5px;
	border-radius: 100%;
	border: 1px solid $pale-grey;
	background-color: $white;
	width: 35px;
	height: 35px;
}

.add-survey-logo-delete-button:before,
.add-survey-logo-delete-button:after {
	position: absolute;
	top: 4px;
	left: 16px;
	content: ' ';
	height: 25px;
	width: 1.5px;
	background-color: $graphite;
}

.add-survey-logo-delete-button:before {
	transform: rotate(45deg);
}

.add-survey-logo-delete-button:after {
	transform: rotate(-45deg);
}

.add-survey-logo-delete-button:hover {
	cursor: pointer;
}

.add-survey-logo-label-name {
	width: 230px;
	margin-left: 34px;
}

.blue-color {
	color: $blue;
}
