$matrix-item-width: 100%;
$matrix-item-height: 120px;

.details-questions-matrix {
	&-title {
		margin: 30px 0;
		font-size: 16px;
		font-weight: 500;
		display: flex;
		align-items: flex-start;
	}
	&-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: calc(100% + 11px);
		height: $matrix-item-height + 20px;
		align-items: flex-end;
		& .details-questions-matrix-label {
			padding-top: 10px;
		}
	}
	&-body {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
	&-answers {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	&-checkbox {
		height: 161px;
		width: $matrix-item-width;
		border: 1px solid $grey;
		margin: 7px;
		display: flex;
		justify-content: center;
		align-items: center;
		&-element {
			margin: calc(#{$matrix-item-height} / 2 - 10px) auto;
			width: 20px;
			height: 20px;
			border: 1px solid $grey;
			&.rounded {
				border-radius: 100%;
			}
		}
	}
	&-container {
		padding-bottom: 80px;
		border-bottom: 1px solid $grey;
	}
	&-input {
		font-family: Rubik;
		font-size: 12px;
		font-weight: 500;
		flex-shrink: 0;
		width: 90%;
		max-height: 100%;
		overflow-wrap: break-word;
		&:focus {
			outline: none;
		}
	}
	&-label {
		border: none;
		width: $matrix-item-width;
		height: 145px;
		margin-top: 7px;
		display: flex;
		align-items: center;
		justify-content: space-around;
		overflow-y: auto;
		position: relative;
		&-add {
			&-answer {
				border: 1px solid black;
				border-radius: 14px;
				cursor: pointer;
			}
		}
		&:first-child {
			display: flex;
			flex-flow: column;
			width: 150%;
			justify-content: space-between;
			margin-top: 13px;
		}
		&.empty {
			border: none;
			margin: 7px;
			margin-right: 0;
			margin-left: 0;
			width: calc(150% - 85px);
		}
		&-box {
			width: calc(100% - 28px);
			height: 110px;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow-y: auto;
			border: 1px solid black;
			&.question-box {
				margin-left: 12px;
			}
		}
	}
}

.details-questions-matrix-label-box::-webkit-scrollbar {
	width: 5px;
	&-track {
		background: $white;
	}
	&-thumb {
		border-radius: 5px;
		background: $grey;
		&:hover {
			background: #454a4e;
		}
	}
}

[contenteditable='true']:empty:before {
	content: attr(placeholder);
	color: $text-grey;
	display: block; /* For Firefox */
}

.details-questions-matrix-substract-answer-svg {
	position: absolute;
	top: 0;
	left: calc(50% - 18px);
	cursor: pointer;
	&.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
}

.details-questions-matrix-substract-question-svg {
	position: absolute;
	left: 0;
	top: calc(50% - 20px);
	cursor: pointer;
	&.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
}

.details-questions-matrix-add-answer-svg {
	cursor: pointer;
	&.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
}

.details-questions-matrix-add-question-svg {
	margin-left: 12px;
	cursor: pointer;
	&.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
}

.details-questions-matrix-edit-answer-svg {
	position: absolute;
	top: 27px;
	right: 30px;
}

.details-questions-matrix-edit-question-svg {
	position: absolute;
	top: 6px;
	right: 14px;
}

.details-questions-matrix-label-box:focus-within
	.details-questions-matrix-edit-answer-svg,
.details-questions-matrix-label-box:focus-within
	.details-questions-matrix-edit-question-svg {
	display: none;
}

.details-questions-matrix-label-box:hover
	.details-questions-matrix-edit-answer-svg,
.details-questions-matrix-label-box:hover
	.details-questions-matrix-edit-question-svg {
	display: none;
}
