.activation-container {
	height: 100%;
	display: flex;
	justify-content: center;
	background-color: $background-edge;
}

.activation-content {
	width: 75%;
	&-top {
		display: flex;
		justify-content: space-between;
	}
}

.activation-logo {
	margin: 40px 0 40px -110px;
}

.activation-texts {
	display: flex;
	flex-direction: column;
	text-align: center;
	border: 1px solid $pale-grey;
	box-shadow: -7px 14px 16px 0 rgba(230, 237, 239, 0.54);
	background-color: $white;
	padding: 80px 170px 50px;
	margin-bottom: 58px;
}

.activation-title {
	font-size: 28px;
	font-weight: 300;
	&:nth-child(2) {
		margin-bottom: 15px;
	}
}

.activation-subtitle {
	font-size: 18px;
	font-weight: 500;
	&:last-of-type {
		margin-bottom: 20px;
	}
}

.activation-steps-container {
	display: flex;
	justify-content: space-evenly;
	margin-bottom: 45px;
}

.activation-step {
	font-size: 18px;
	font-weight: 500;
}

.activation-step-number {
	font-size: 29px;
	color: $red;
}
