.details-container-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: $white;
	border: 1px solid $pale-grey;
	margin-top: 50px;
	margin-bottom: 46px;
	box-shadow: -7px 14px 16px 0 rgba(230, 237, 239, 0.54);
	.btn.btn-blue {
		margin-right: 50px;
	}
}

.details-container-content {
	display: flex;
	flex-direction: column;
	padding: 30px;
	flex-grow: 1;
	word-break: break-all;
}

.details-container-label {
	font-size: 14px;
	font-weight: 500;
	color: $lighter-black;
}

.details-container-name {
	font-size: 28px;
	font-weight: 300;
	color: $lighter-black;
	word-break: break-all;
}

.details-container-sorting {
	padding: 30px 0 20px;
}

.sorting-pages,
.sorting-questions {
	border: 1px solid $grey;
	border-radius: 3px;
	background-color: white;
	padding: 10px 20px;
	font-size: 12px;
	font-weight: 700;
}

.sorting-highlighted {
	border: 1px solid $blue;
	color: $blue;
}

.sorting-questions {
	margin-left: 10px;
}

.details-container-page {
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: #f9f9fb;
	padding: 20px 20px 40px;
	margin-bottom: 40px;
}

.details-container-page:hover .page-delete {
	z-index: 10;
}

.page-delete {
	right: 20px;
	position: absolute;
	z-index: -10;
	display: flex;
	gap: 20px;
	.page-delete__button {
		color: $blue;
		width: 85px;
		height: 14px;
		font-size: 12px;
		font-weight: 500;
		white-space: nowrap;
		text-transform: uppercase;
		&:hover {
			cursor: pointer;
			opacity: 0.6;
		}
	}
}

.page-number {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	font-weight: 500;
	color: $lighter-black;
	text-align: center;
	padding-bottom: 40px;
	gap:15px;
	.circle {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		background-color: $dark-blue;
		width: 25px;
		height: 25px;
		font-size: 10px;
		color:$white;
	}
}

.page-add-description {
	border: 1px solid #454a4e;
	border-radius: 2px;
	background-color: rgba(0, 0, 0, 0.06);
	padding: 15px 200px;
	display: block;
	margin: 0 auto;
	font-size: 12px;
	font-weight: 500;
}

.details-container-page-add {
	padding-bottom: 40px;
}

.page-add-text {
	display: flex;
	align-items: center;
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	color: $blue;
}

.page-add-text:hover {
	& {
		cursor: pointer;
		color: black;
	}
	&.page-add-text:before,
	&.page-add-text:after {
		color: black;
	}
}

.page-add-text:before,
.page-add-text:after {
	content: '';
	border-top: 2px solid;
	margin: 0 20px 0 0;
	flex: 1 0 20px;
	color: $grey;
}

.page-add-text:after {
	margin: 0 0 0 20px;
}

.details-cancel-button-container {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 30px;
	.btn.btn-blue {
		margin-right: 10px;
	}
}

.details-save-button {
	font-size: 12px;
	font-weight: 500;
	background-color: #454a4e;
	border: 1px solid $grey;
	color: $white;
	border-radius: 4px;
	padding: 13px 65px;
	margin-right: 10px;
}
.question-integration-element {
	margin-bottom: 40px;
}
