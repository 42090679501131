.confirm-container {
	height: 100%;
	display: flex;
	justify-content: center;
	background-color: $background-edge;
}

.confirm-content {
	width: 75%;
	&-top {
		display: flex;
		justify-content: space-between;
		padding: 20px;
	}
}

.confirm-logo {
	margin: 40px 0 40px -110px;
}

.confirm-texts {
	text-align: center;
	border: 1px solid $pale-grey;
	box-shadow: -7px 14px 16px 0 rgba(230, 237, 239, 0.54);
	background-color: $white;
	padding: 80px 170px 50px;
}

.confirm-text {
	font-size: 28px;
	font-weight: 300;
	margin-bottom: 30px;
}
