.settings-content-password-title {
	font-size: 26px;
	font-weight: 500;
}

.settings-content-password-subtitle {
	width: 50%;
	font-size: 14px;
	margin: 34px 0;
}
