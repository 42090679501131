button {
	border: none;
	cursor: pointer;
}

input:focus {
	outline: none;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Rubik', sans-serif;
	background-color: $background-edge;
}

button:focus {
	outline: 0;
}

.btn {
	cursor: pointer;
	position: relative;
	text-decoration: none;
	user-select: none;
	border-radius: 4px;
	height: 40px;
	width: 180px;
	line-height: 40px;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	align-items: center;
	color: $white;
	&-red {
		background-color: $red;
	}
	&-blue {
		background-color: $dark-blue;
		&.add-text {
			width: 600px;
			font-size: 12px;
			margin: 0 auto;
		}
	}
	&:active {
		box-shadow: inset 0 1px 3px 0 $black;
	}
	&:disabled {
		background-color: $grey;
		cursor: default;
	}
	&-red:hover:enabled {
		background-color: $red_hover;
	}
	&-blue:hover:enabled {
		background-color: $dark-blue_hover;
	}
}

.loader {
	padding: 33px;
	text-align: center;
	align-items: center;
}

// FIREFOX
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

// https://justmarkup.com/log/2015/07/dealing-with-long-words-in-css/
.break-long-words {
	overflow-wrap: break-word;
	word-wrap: break-word;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
}

@mixin fake-checkbox-mixin() {
	.fake-checkbox-container {
		display: block;
		position: relative;
		padding-left: 35px;
		margin-bottom: 12px;
		cursor: pointer;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.fake-checkbox-container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		background-color: $white;
		border: 1px solid $black;
		border-radius: 2px;
	}
	.fake-checkbox-container:hover input ~ .checkmark {
		background-color: $white;
		border-color: $pale-blue;
	}
	.fake-checkbox-container input:checked ~ .checkmark {
		background-color: $white;
		border-color: $pale-blue;
	}
	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}
	.fake-checkbox-container input:checked ~ .checkmark:after {
		display: block;
	}
	.fake-checkbox-container .checkmark:after {
		left: 6px;
		top: 2px;
		width: 4px;
		height: 9px;
		border: solid $blue;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}

.text-button {
	text-align: center;
	border: none;
	background-color: inherit;
	padding: 14px;
	font-size: 14px;
	width: auto;
	cursor: pointer;
	display: inline-block;

	&:active {
		border: none;
	}
}