.surveys-list-container {
	padding-top: 63px;
}

.surveys-list-title {
	font-size: 26px;
	font-weight: 500;
	padding: 63px 0 40px;
}

.surveys-list-name.bold,
.surveys-list-sort-created,
.surveys-list-sort-end {
	position: relative;
	border: 1px solid white;
}

.surveys-list-name.bold:hover,
.surveys-list-sort-created:hover,
.surveys-list-sort-end:hover {
	background-color: $white;
	border-color: $grey;
	border-radius: 2px;
	cursor: pointer;
}

.surveys-list-name.bold:hover:after,
.surveys-list-sort-created:hover:after,
.surveys-list-sort-end:hover:after {
	content: '';
	width: 8px;
	height: 8px;
	border-bottom: 2px solid $red;
	border-right: 2px solid $red;
	transform: rotate(45deg);
	position: absolute;
	top: 8px;
	right: 25px;
}

.surveys-list-name.bold.selected,
.surveys-list-sort-created.selected,
.surveys-list-sort-end.selected {
	background-color: $white;
	border-color: $grey;
	border-radius: 2px;
}

.surveys-list-name.bold.selected:after,
.surveys-list-sort-created.selected:after,
.surveys-list-sort-end.selected:after {
	content: '';
	width: 8px;
	height: 8px;
	border-bottom: 2px solid $red;
	border-right: 2px solid $red;
	transform: rotate(45deg);
	position: absolute;
	top: 8px;
	right: 25px;
}

.surveys-list-name.bold.selected.arrow-transformation:after,
.surveys-list-sort-created.selected.arrow-transformation:after,
.surveys-list-sort-end.selected.arrow-transformation:after {
	transform: rotate(225deg);
	top: 12px;
}

.surveys-list-sort {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex: 1 1 70%;
	height: 80px;
}

.surveys-list-sort p {
	flex: 1 1 25%;
	text-align: center;
}

.surveys-list-sort-status.colored {
	font-weight: 500;
	color: $blue;
}

.surveys-list-name-container {
	flex: 1 1 30%;
}

.surveys-list-name-container.bold {
	display: flex;
	justify-content: center;
}

.surveys-list-name.bold {
	width: 47%;
	justify-content: center;
}

.surveys-list-name.bold,
.surveys-list-sort.bold p {
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
}

.surveys-list-name {
	display: flex;
	padding-left: 20px;
	align-items: center;
}
.surveys-list-name-container.position {
	min-height: 80px;
	display: flex;
	align-items: center;
	cursor: pointer;
	word-break: break-all;
}

// Interviewers

.surveys-list-interviewers-main {
	text-decoration: none;
	color: #000;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #f1f1f5;
	border-right: 1px solid white;
	border-left: 1px solid white;
	font-size: 14px;
	width: 100%;
}

.surveys-list-interviewers-labels {
	display: flex;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 12px;
	border-bottom: 1px solid #f1f1f5;
	border-right: 1px solid white;
	border-left: 1px solid white;
	padding-bottom: 20px;
}

.survey-list-interviewers-container {
	width: 100%;
	height: 80px;
	display: flex;
	align-items: center;
	&.link {
		cursor: pointer;
	}
}

.surveys-list-interviewers-name {
	flex: 5;
}

.surveys-list-interviewers-created,
.surveys-list-interviewers-end,
.surveys-list-interviewers-amount,
.surveys-list-interviewers-points {
	display: flex;
	justify-content: center;
	flex: 2;
}

.surveys-list-interviewers-status {
	font-size: 12px;
	font-weight: 500;
	display: flex;
	justify-content: center;
	flex: 1;
	text-transform: uppercase;
	&.red {
		color: $red;
	}
	&.blue {
		color: $blue;
	}
	&.label {
		font-weight: bold;
	}
}
