.page-interviewer-container {
	padding-top: 63px;
	.surveys-list-panel {
		justify-content: flex-end;
	}
}

.interviewer-table-header-row,
.interviewer-table-data-row {
	display: flex;
	align-items: center;
	height: 77px;
	border-bottom: 1px solid $grey;
}

.interviewer-table-header-row {
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
}

.interviewer-list-panel-content-elements-numbers {
	margin-left: 25px;
	font-weight: 500;
	flex: 1 1 5%;
}

.interviewer-list-panel-content-elements-names {
	flex: 1 1 20%;
	&.table-header {
		font-weight: bold;
	}
}

.interviewer-list-panel-content-elements-email {
	flex: 1 1 25%;
}

.interviewer-list-panel-content-elements-district {
	flex: 1 1 15%;
}

.interviewer-list-panel-content-elements-phone {
	flex: 1 1 18%;
}

.interviewer-list-panel-content-elements-delete {
	flex: 1 1 17%;
}

.interviewer-table-data-row {
	font-size: 14px;
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
	&:hover {
		& {
			box-shadow: 0 2px 6px 0 rgba(230, 230, 230, 0.5);
			border-left: 1px solid $grey;
			border-right: 1px solid $grey;
		}
		.delete-btn-wrapper {
			display: block;
		}
	}
}

.interviewer-table-data-row:hover
	.interviewer-list-panel-content-elements-district,
.interviewer-table-data-row:hover
	.interviewer-list-panel-content-elements-phone {
	color: #fcfdff;
}

.interviewer-list-panel-content-elements-names {
	font-weight: 500;
	color: $black;
	text-decoration: none;
	flex: 1 1 20%;
}

.interviewer-list-panel-content-elements-delete {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1 1 17%;
	cursor: pointer;
}

.interviewer-pagination {
	margin-top: 50px;
}

.interviewer-table-empty {
	display: flex;
	justify-content: center;
	margin: 30px 0;
}

.interviewer-list-form-input-message {
	position: absolute;
	bottom: -18px;
	font-size: 12px;
	font-weight: normal;
	&.error {
		color: #ff0000;
	}
}

.interviewer-list-form-input {
	display: flex;
	margin-bottom: 25px;
	position: relative;
	input {
		width: 74%;
		height: 42px;
		border: 1px solid $grey;
		padding-left: 26px;
		margin-right: 30px;
	}
	&.error input {
		border: 1px solid #f00;
	}
}

.interviewer-list-form-add {
	font-size: 16px;
	font-weight: 500;
	padding: 30px 0 20px;
}

.interviewer-list-form-instruction {
	width: 65%;
	font-size: 14px;
	letter-spacing: 0.7px;
	padding-bottom: 20px;
}
