* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.header {
	height: 108px;
	display: flex;
	background-color: $background-edge;
	border-bottom: 1px solid $pale-grey;
}

.header-button {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex: 1 1 85%;
	margin-right: 25px;
	.btn.btn-red {
		display: flex;
		justify-content: center;
	}
	.header-language {
		margin-right: 25px;
	}
}

.header-button-add {
	margin-left: 10px;
}

.header-button-item {
	height: 36px;
	width: 160px;
	font-size: 14px;
	font-weight: 300;
	color: $white;
	border-radius: 3px;
	text-align: center;
	line-height: 36px;
	margin-right: 30px;
	text-decoration: none;
	background-color: #454a4e;
}

.header-button-item::after {
	content: '+';
	color: #454a4e;
	background-color: $white;
	border-radius: 40px;
	padding: 0 4px;
	margin-left: 5px;
}

.header-user-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex: 1 1 15%;
	border-left: 1px solid $pale-grey;
	justify-content: space-evenly;
}

.header-user-image {
	position: relative;
	border-radius: 50%;
	width: 56px;
	height: 56px;
	object-fit: contain;
	&-icon {
		position: relative;
		border-radius: 50%;
		background-color: $blue;
		width: 56px;
		height: 56px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: white;
	}
}

.header-user-extend {
	width: 8px;
	height: 8px;
	border-bottom: 2px solid black;
	border-right: 2px solid black;
	transform: rotate(45deg);
	&.extended {
		transform: rotate(225deg);
	}
}

.header-dropdown-container {
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.header-user-logout,
.header-user-logout2 {
	position: absolute;
	top: 107px;
	right: 0;
	width: calc(15% - 4px);
	height: 50px;
	background: $background-edge;
	border: 1px solid $pale-grey;
	box-shadow: -2px 2px 16px 0 rgba(230, 237, 239, 0.54);
	z-index: 100;
	&:hover {
		background: $grey;
	}
}

.header-user-logout2 {
	top: 157px;
}
