.scale-details-title {
	font-size: 16px;
	font-weight: 500;
	text-align: left;
	margin: 30px 0;
}

.scale-details-input-text-container {
	display: flex;
	justify-content: space-around;
	align-items: center;
	border-bottom: 1px solid #e4e4e5;
}

.scale-details-left-side {
	width: 50%;
	padding-right: 15px;
	p {
		text-align: left;
		height: 17px;
		font-size: 14px;
		margin-bottom: 30px;
	}
	input,
	select {
		width: 100%;
		height: 43px;
		border-radius: 2px;
		border: 1px solid $text-grey;
		margin-bottom: 30px;
		padding-left: 13px;
	}
}
.scale-details-right-side {
	width: 50%;
	padding-left: 15px;
	p {
		text-align: left;
		height: 17px;
		font-size: 14px;
		margin-bottom: 30px;
	}
	input,
	select {
		width: 100%;
		height: 43px;
		border-radius: 2px;
		border: 1px solid $text-grey;
		margin-bottom: 30px;
		padding-left: 13px;
	}
}

.scale-details-input-number-container {
	display: flex;
	justify-content: space-around;
	align-items: center;
	border-bottom: 1px solid #e4e4e5;
	p {
		margin: 30px 0;
	}
}
