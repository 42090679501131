$sidebar-gradient-blue-bottom: #292d47;
$sidebar-gradient-gray-left: #f3f4f6;
$sidebar-gradient-gray-right: #e9edf0;
$background-edge: #fcfdff;
$background-middle: #f7f9fc;

$black: #000;
$lighter-black: #282d32;
$green: #008000;
$dark-blue: #34395d;
$darker-blue: #2a2c44;
$red: #f7465f;
$red_hover: #f8546a;
$dark-blue_hover: #404672;
$pale-red: #f7d6db;
$blue: #05ace9;
$pale-blue: #aee7fc;
$pale-blue_2: rgba(125, 207, 238, 0.15);
$grey: #e4e4e5;
$pale-grey: #f1f1f5;
$text-grey: #818891;
$graphite: #979797;
$white: #fff;
$transparent: transparent;

$violet: #53597e;
$dark-violet: #2c304c;

$box-shadow-small: 5px 4px 5px 0 rgba(181, 178, 178, 0.5);
$box-shadow-background: 3px 5px 9px 0 rgba(165, 165, 165, 0.5);
$box-shadow-simple-filter: 0px 0px 10px 1px rgba(165, 165, 165, 0.5);

@mixin transition($time, $content, $type) {
	-webkit-transition: $content $time $type;
	-ms-transition: $content $time $type;
	transition: $content $time $type;
}
