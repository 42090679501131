.settings-content-account {
	display: flex;
	flex-direction: column;
}

.settings-content-account-main {
	display: flex;
}

.settings-content-account-inputs {
	max-width: 50%;
	p {
		font-size: 16px;
		font-weight: 500;
		margin: 40px 0 30px;
		&.error {
			font-size: 12px;
			color: $red;
			font-weight: 400;
			margin-top: -30px;
		}
	}
	p:first-child {
		margin-top: 0;
	}
	input {
		width: 325px;
		height: 42px;
		border: 1px solid $grey;
		padding-left: 26px;
		margin-bottom: 40px;
	}
}

.settings-content-account-logo {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	height: 155px;
	width: 155px;
	border: 1px solid transparent;
	border-radius: 50%;
	box-shadow: 0 2px 20px 0 rgba(37, 38, 94, 0.1);
	margin: 48px 0 0 88px;
	img {
		height: 153px;
		width: 153px;
		object-fit: contain;
		border-radius: 50%;
	}
	&:hover:after {
		content: '';
		position: absolute;
		width: 154px;
		height: 77px;
		bottom: 0;
		background-color: #d8d8d8;
		opacity: 0.8;
		border: 1px solid transparent;
		border-bottom-right-radius: 308px;
		border-bottom-left-radius: 308px;
	}
	&:hover .settings-content-account-logo-text {
		z-index: 100;
	}
}

.settings-content-account-logo-text {
	position: absolute;
	bottom: 37px;
	font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
	z-index: -1;
	label {
		cursor: pointer;
	}
}

.settings-content-account-buttons {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	position: relative;
	button:first-child {
		margin-right: 10px;
	}
	p {
		position: absolute;
		color: $blue;
		bottom: -20px;
		font-size: 12px;
	}
}
