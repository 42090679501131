.details-container-buttons {
	width: 550px;
	margin: 0 auto;
	padding-top: 50px;
	display: flex;
	justify-content: center;
	.btn.btn-blue {
		& {
			display: none;
			margin: 0 5px;
		}
		&.active-button {
			display: inline-block;
		}
	}
	.btn.btn-red {
		& {
			margin: 0 5px;
		}
	}

	.btn.btn-blue.options:first-child:before {
		content: '';
		width: 10px;
		height: 10px;
		border-bottom: 2px solid white;
		border-right: 2px solid white;
		transform: rotate(135deg);
		position: absolute;
		top: 0;
		bottom: 0;
		margin-top: auto;
		margin-bottom: auto;
		left: 15px;
	}

	.btn.btn-red.right-arrows:last-child:after {
		content: '';
		width: 10px;
		height: 10px;
		border-bottom: 2px solid white;
		border-right: 2px solid white;
		transform: rotate(315deg);
		position: absolute;
		top: 0;
		bottom: 0;
		margin-top: auto;
		margin-bottom: auto;
		right: 15px;
	}

	.btn.btn-red.summary:last-child:before {
		content: '';
		width: 10px;
		height: 10px;
		border-bottom: 2px solid white;
		border-right: 2px solid white;
		transform: rotate(135deg);
		position: absolute;
		top: 0;
		bottom: 0;
		margin-top: auto;
		margin-bottom: auto;
		left: 15px;
	}

	&.summary {
		flex-direction: row-reverse;
	}
}
