.add-survey-popup {
	position: absolute;
	z-index: 100;
	width: 100%;
	top: 400px;
	margin: auto;
	border: 1px solid #ceced4;
	background-color: white;
}

.add-survey-title.popup {
	text-align: center;
	padding: 20px 0;
}

.add-survey-popup-list {
	overflow-y: scroll;
	height: 400px;
	list-style-type: none;
}

.add-survey-popup-list::-webkit-scrollbar {
	width: 10px;
}

.add-survey-popup-list::-webkit-scrollbar-track {
	background: $white;
}

.add-survey-popup-list::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background: $blue;
}

.add-survey-popup-list::-webkit-scrollbar-thumb:hover {
	background: #5d38db;
}

.add-survey-popup-missing-surveys {
	margin-top: 20px;
	font-size: 12px;
	text-align: center;
}

.add-survey-popup-list-item {
	height: 80px;
	display: flex;
	align-items: center;
	border-top: solid 1px $pale-grey;
	border-bottom: solid 1px $pale-grey;
	width: 100%;
	padding:0 40px;
	gap:20px;

	.add-survey-item-button{
		flex-shrink: 0;
	}
	
	.add-survey-item-status{
		text-align: center;
		width:200px;
		flex-shrink: 0;
	}

	.add-survey-radio{
		display: flex;
		height: 100%;
		overflow-x: auto;
		flex-grow: 1;
		line-height: 20px;
		font-size: 14px;
		font-weight: 500;
		min-width: 200px;
		position: relative;
		padding:0;
		input{
			display: none;
		}

		&::-webkit-scrollbar {
			width: 10px;
			height: 8px;
		}
		
		&::-webkit-scrollbar-track {
			background: $grey;
		}
		
		&::-webkit-scrollbar-thumb {
			border-radius: 5px;
			background: $blue;
		}
		
		&::-webkit-scrollbar-thumb:hover {
			background: #5d38db;
		}
	}
}


.add-survey-popup-label {
	height: 100%;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 500;
	padding-left: 30px;
}

.add-survey-popup-label:before {
	bottom: 0;
	margin-top: auto;
	margin-bottom: auto;
}

.add-survey-popup-label.add-survey-popup-label:after {
	top: 33px;
}

.add-survey-popup-close {
	position: absolute;
	right: 20px;
	top: 18px;
	width: 25px;
	height: 25px;
	opacity: 0.3;
	cursor: pointer;
}

.add-survey-popup-close:hover {
	opacity: 1;
}

.add-survey-popup-close:before,
.add-survey-popup-close:after {
	position: absolute;
	left: 15px;
	content: ' ';
	height: 25px;
	width: 2px;
	background-color: #333;
}

.add-survey-popup-close:before {
	transform: rotate(45deg);
}

.add-survey-popup-close:after {
	transform: rotate(-45deg);
}

.add-survey-popup-button-container {
	padding-right: 15px;
	display: flex;
	justify-content: flex-end;
	.btn.btn-red {
		margin: 20px 39px 20px 0;
	}
}

.add-survey-popup-loading-survey {
	margin: 30px;
}
