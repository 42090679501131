.footer {
	position: fixed;
	text-align: center;
	bottom: 0;
	width: 100%;
	height: 28px;
	font-size: 14px;
	line-height: 28px;
	background-color: #c6c6c6;
	border: 1px solid #868686;
}
