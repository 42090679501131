.companies-content-profile {
    input {
        width: 90%;
        height: 42px;
        border: 1px solid $grey;
        padding-left: 26px;
        margin-bottom: 30px;
        &.error {
            border: 1px solid #f00;
        }
    }
    input:disabled{
        border: solid 1px #e4e4e5;
        background-color: #ffffff;
    }
}

.companies-content-profile-title {
    font-size: 26px;
    font-weight: 500;
}

.companies-content-profile-address,
.companies-content-profile-nip {
    font-size: 16px;
    font-weight: 500;
    padding: 30px 0;
}

.companies-content-profile-inputs {
    width: 90%;
    display: flex;
    justify-content: space-between;
    input {
        width: 48%;
        &.error {
            border: 1px solid #f00;
        }
    }
}

.companies-content-profile-inputs-medium {
    height: 72px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    input {
        width: 50%;
        &.error {
            border: 1px solid #f00;
        }
    }
}

.companies-content-profile-inputs-small {
    height: 72px;
    width: 50%;
    display: flex;
    justify-content: space-between;
    input {
        width: 30%;
        &.error {
            border: 1px solid #f00;
        }
    }
}


// Panelists

.companies-content-profile-panelist {
    display: flex;
    flex-direction: column;
    input[type="text"] {
        width: 90%;
        height: 42px;
        border: 1px solid $grey;
        padding-left: 26px;
        margin-bottom: 30px;
    }
    input:last-of-type[type="text"] {
        margin-bottom: 40px;
    }
}

.companies-content-profile-panelist-row {
    display: flex;
    flex-direction: row;
    div {
        width: 50%;
        padding-right: 20px;
    }
    &-inputs {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 40px;
    }
    &-radio {
        position: relative;
        input {
            display: none;
        }
        label {
            margin-left: 25px;
        }
        label:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            box-sizing: border-box;
            border: 1px solid $black;
            border-radius: 50%;
            background: white;
        }
        label:after {
            content: '';
            position: absolute;
            top: 4px;
            left: 4px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: $blue;
            transform: scale(0);
            transition: transform 0.25s ease;
        }
        input[type="radio"]:checked + label:before {
            border-color: $blue;
        }

        input[type="radio"]:checked + label:after {
            transform: scale(1);
        }
        &:last-child {
            margin-left: 55px;
        }
    }
}

// Interviewers

.companies-content-profile-data {
    font-size: 16px;
    font-weight: 500;
    padding: 30px 0;
}
