.details-container-page {
	background-color: #f9f9fb;
	padding: 0px 20px;
	margin-bottom: 20px;

	.page-delete {
		top: 20px;
		position: absolute;
	}
	.add-text-container {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.page-number {
		padding: 20px 0;
	}
}

.details-container-page:hover .page-delete {
	z-index: 10;
}

.details-container-description {
	border: 1px solid $graphite;
	background-color: $white;
	text-align: center;
	margin-bottom: 35px;
	font-size: 14px;
	min-height: 153px;
	position: relative;
}

.element-summary-mask {
	background-color: $white;
	opacity: 0.9;
	position: absolute;
	width: 100%;
	height: 100%;
	right: 0px;
	z-index: 30;
}

.element-summary-editbar {
	visibility: hidden;
	position: absolute;
	width: 100%;
	left: 0;
	z-index: 15;
	padding: 0 30px;
}

.details-container-description:hover .element-summary-editbar {
	visibility: visible;
}

.details-container-footer {
	padding: 0 30px 25px;
	display: flex;
	gap:10px;
	align-items: center;
	flex-flow: row-reverse;
	.btn{
		flex-shrink: 0;
	}

	.validation-box {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: auto;
		width: 50%;
		height: 40px;
		border: 1px solid $red_hover;
		border-radius: 5px;
		color: $red_hover;
	}
}
