.pageLoader{
    position: fixed;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    gap:20px
  }
  .image{
    max-width: 100%;
    height: auto;
  }

  .logo{
    margin-bottom: 5%;
  }

  @media screen and (max-width: 767px) {
    .logo{
        margin-bottom: 10%;
      }
   
  }