.details-detail-question-header {
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	border-bottom: 1px solid $grey;
	&-input {
		width: 100%;
		height: 43px;
		border-radius: 2px;
		border: 1px solid $text-grey;
		display: flex;
		font-family: Rubik;
		padding-left: 27px;
		&.hidden {
			display: none;
		}
		&.error {
			border-color: $red_hover;
		}
	}
	&-infobox {
		font-size: 10px;
		font-weight: 300;
		color: $lighter-black;
		opacity: 0.5;
		margin-top: 10px;
		&.last {
			margin-bottom: 30px;
			&.hidden {
				display: none;
			}
		}
	}
	&-title {
		font-size: 16px;
		font-weight: 500;
		color: $lighter-black;
		margin: 30px 0;
	}
	&-subtitle-container {
		margin-top: 22px;
		margin-bottom: 30px;
	}
	&-subtitle {
		font-size: 16px;
		font-weight: 500;
		color: $lighter-black;
		margin-top: 22px;
		margin-bottom: 30px;
		display: inline;
	}
	&-arrow {
		width: 8px;
		height: 8px;
		border-bottom: 2px solid black;
		border-right: 2px solid black;
		position: absolute;
		transform: rotate(45deg);
		margin-top: 4px;
		margin-left: 13px;
		cursor: pointer;
		&.extended {
			transform: rotate(225deg);
			margin-top: 7px;
		}
	}
}
