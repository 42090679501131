.surveys-list-panel {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid $pale-grey;
	box-shadow: -7px 14px 16px 0 rgba(230, 237, 239, 0.54);
	background-color: $white;
	min-height: 92px;
}

.surveys-list-panel-filter-container {
	display: flex;
	align-items: center;
	width: 370px;
	p {
		font-size: 14px;
		font-weight: 500;
		margin-left: 40px;
	}
}

.surveys-list-panel-filter {
	width: 100%;
	white-space: nowrap;
	font-size: 12px;
	font-weight: 700;
	background-color: white;
	border: 1px solid $grey;
	border-radius: 3px;
	padding: 12px 20px;
	cursor: pointer;
}

.surveys-list-panel-filter:first-of-type {
	margin-left: 40px;
}

.surveys-list-panel-filter.surveys-list-border {
	border: 1px solid $blue;
	color: $blue;
}

.surveys-list-panel-panelists-export {
	margin-left: 30px;
}

.surveys-list-panel-search-container {
	display: flex;
	align-items: center;
	width: 50%;
	margin-right: 40px;
}

.surveys-list-panel-text {
	font-size: 14px;
	font-weight: 500;
	margin: 0 25px 0 30px;
}

.surveys-list-panel-search {
	display: flex;
	width: 100%;
	height: 42px;
	position: relative;
}

.surveys-list-panel-icon {
	position: absolute;
	top: 0;
	bottom: 0;
	margin-top: auto;
	margin-bottom: auto;
	left: 10px;
	width: 26px;
	height: 26px;
	color: $text-grey;
}

.surveys-list-panel-input {
	width: 100%;
	height: 100%;
	padding-left: 40px;
	border: 1px solid $grey;
	border-radius: 3px;
}

.surveys-list-panel-button {
	height: 100%;
	font-size: 12px;
	font-weight: 700;
	color: $blue;
	background: $white;
	border: 1px solid $blue;
	border-radius: 3px;
	padding: 0 20px;
	cursor: pointer;
}
