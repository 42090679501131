html,
body,
#root,
.layout-container {
	height: 100%;
}

.layout {
	padding-left: 200px;
	padding-right: 100px;
	padding-bottom: 100px;
	background-color: $background-edge;
}

.layout-extended {
	padding-left: 300px;
}
