.add-survey-radio.three {
	display: flex;
	align-items: center;
	position: relative;
}

.add-survey-radio.three:nth-child(2):after,
.add-survey-radio.three:last-child:before {
	content: '';
	background-color: $grey;
	position: absolute;
	width: 1px;
	height: 69px;
	right: 255px;
}

.add-survey-colors-radio-label {
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 5px;
	width: 150px;
	padding: 20px 0;
	p {
		font-size: 14px;
		font-weight: 500;
	}
}

.add-survey-img-colors {
	width: 116px;
	margin-bottom: 22px;
}
