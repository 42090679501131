.interviewer-box-details {
	background-color: #f9f9fb;
	height: 90px;
	display: flex;
	align-items: center;
	// margin-bottom: 50px;
}

.interviewer-box-details button {
	height: 40px;
	background-color: white;
	margin-left: 40px;
	width: 200px;
	font-size: 12px;
	font-weight: bold;
}

.interviewer-box-details-active {
	border: 1px solid #05ace9;
	border-radius: 2px;
	color: #05ace9;
}
