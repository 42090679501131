.integration-summary-answer {
	font-size: 14px;
	padding-bottom: 45px;
	text-align: left;
}
.integration-summary-value {
	margin-top: 10px;
}
.integration-summary-label {
	font-weight: 500;
}
