.preview-finished-logo {
	margin-top: 40px;
	margin-left: 40px;
	margin-bottom: 70px;
}

.preview-finished-container {
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	text-align: center;
	width: 70%;
	box-shadow: -7px 14px 16px 0 rgba(230, 237, 239, 0.54);
	border: solid 1px #f1f1f5;
	margin: auto;
	margin-bottom: 30px;
	padding-top: 60px;
	padding-bottom: 40px;
	& p,
	.points {
		margin-right: 30px;
		margin-left: 30px;
		font-size: 28px;
		font-weight: 300;
		margin-bottom: 20px;
		&:first-child {
			margin-bottom: 10px;
		}
	}
	.points {
		margin-bottom: 30px;
	}
	.descripton {
		margin-bottom: 30px;
		font-size: 18px;
		font-weight: 500;
		text-align: center;
		max-width: 382px;
	}
	& button {
		margin-top: 10px;
		margin-bottom: 50px;
	}
}
