@mixin chevron($rotate, $color) {
	// export this to external mixins
	content: '';
	width: 8px;
	height: 8px;
	border-bottom: 2px solid $color;
	border-right: 2px solid $color;
	transform: rotate($rotate);
}
@mixin customRadio() {
	.span-title {
		font-size: 14px;
		font-weight: normal;
		color: $black;
	}
	.container {
		display: flex;
		position: relative;
		padding-left: 35px;
		cursor: pointer;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		background-color: $white;
		border-radius: 50%;
		border: 1px solid $black;
	}
	.container input:checked ~ .checkmark {
		background-color: $white;
		border: 1px solid $blue;
	}
	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}
	.container input:checked ~ .checkmark:after {
		display: block;
	}
	.container .checkmark:after {
		top: 3px;
		left: 3px;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background: $blue;
	}
	label {
		display: flex;
		height: 20px;
		line-height: 20px;
	}
}
@mixin customCheckbox() {
	.span-title {
		font-size: 14px;
		font-weight: normal;
		color: $black;
	}
	.container {
		display: block;
		position: relative;
		padding-left: 35px;
		margin-bottom: 12px;
		cursor: pointer;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		border-radius: 3px;
		border: 1px solid $black;
		background-color: $white;
	}
	.container input:checked ~ .checkmark {
		background-color: $white;
		border-color: $blue;
	}
	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}
	.container input:checked ~ .checkmark:after {
		display: block;
	}
	.container .checkmark:after {
		left: 6px;
		top: 2px;
		width: 4px;
		height: 10px;
		border: solid $blue;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}
.panelist-dashboard-title {
	margin-bottom: 50px;
	font-size: 26px;
	font-weight: 500;
}
.panelist-dashboard-info-container {
	& {
		border: 1px solid $grey;
		max-width: 470px;
		padding: 40px 30px 48px;
		position: relative;
		margin-bottom: 50px;
	}
	.delete-wrapper {
		& {
			position: absolute;
			top: 18px;
			right: 20px;
			display: flex;
			flex-flow: row;
		}
		&:hover {
			cursor: pointer;
		}
		img {
			margin-right: 10px;
		}
		span {
			font-size: 12px;
			font-weight: 500;
			color: $blue;
			line-height: 17px;
			text-transform: uppercase;
		}
	}
}
.panelist-dashboard-info {
	& {
		display: flex;
		flex-flow: row;
	}
	.avatar-wrapper {
		width: 100px;
		height: 100px;
		margin-right: 30px;
	}
	.info-name {
		font-size: 18px;
		font-weight: 500;
		line-height: 1.83;
		padding-bottom: 12px;
		border-bottom: 1px solid $grey;
		margin-bottom: 10px;
	}
	.info-email,
	.info-polls-label,
	.info-polls-amount {
		font-size: 14px;
	}
	.info-email,
	.info-polls-amount {
		font-weight: 500;
	}
	.info-polls-label {
		margin-right: 5px;
	}
}
.panelist-dashboard-detail-buttons-wrapper {
	& {
		display: flex;
		flex-flow: row;
		background-color: $pale-grey;
		padding: 25px 40px;
		justify-content: flex-start;
	}
	.btn {
		& {
			font-size: 12px;
			color: $black;
			font-weight: bold;
			background-color: $white;
			border: 1px solid $grey;
		}
		&.active {
			color: $blue;
			border: 1px solid $blue;
		}
		&:active {
			box-shadow: none;
		}
	}
	.btn + .btn {
		margin-left: 30px;
	}
}
.panelist-dashboard-title-row,
.panelist-dashboard-data-row {
	& {
		display: flex;
		align-items: center;
		height: 75px;
		border-bottom: 1px solid $grey;
	}
	.poll {
		flex: 71;
	}
	.time {
		flex: 13;
	}
	.date {
		flex: 18;
	}
	.status {
		flex: 17;
	}
}
.panelist-dashboard-title-cell {
	& {
		display: flex;
		justify-content: center;
	}
	&.poll {
		justify-content: flex-start;
		padding-left: 36px;
		.btn.btn-panelist-dashboard-title-cell {
			justify-content: flex-start;
			padding: 0;
			&:hover {
				border-color: $transparent;
				cursor: auto;
			}
		}
	}
}
.btn.btn-panelist-dashboard-title-cell {
	display: flex;
	justify-content: center;
	background-color: $white;
	font-size: 12px;
	font-weight: 700;
	color: $black;
	border: 1px solid $transparent;
	width: initial;
	padding: 0 15px;
	.chevron {
		display: none;
	}
	&:hover {
		border: 1px solid $black;
		border-radius: 0;
		.chevron {
			display: inline-block;
			position: relative;
			margin-left: 7px;
			top: -2px;
			&.asc {
				@include chevron(45deg, $black);
			}
			&.desc {
				@include chevron(225deg, $black);
				top: 1px;
			}
		}
	}
	&:active {
		box-shadow: none;
	}
}
.panelist-dashboard-data-cell {
	& {
		font-size: 14px;
		display: flex;
		justify-content: center;
	}
	&.poll {
		justify-content: flex-start;
		font-weight: 300;
		padding-left: 36px;
	}
	&.status {
		color: $blue;
		font-weight: 500;
	}
}
.panelist-dashboard-profile-data-wrapper {
	& {
		display: flex;
		flex-flow: row;
		margin-top: 50px;
		background-color: $pale-grey;
		padding: 30px 30px 30px 0;
		margin-bottom: 70px;
	}
	.profile-data-left {
		flex: 3;
		display: flex;
		flex-flow: column;
	}
	.profile-data-right {
		flex: 8;
		background-color: $white;
		border: 1px solid $grey;
		padding: 40px 92px 0 30px;
	}
	.btn.btn-profile-data-left {
		background-color: $transparent;
		color: $black;
		text-align: left;
		font-size: 16px;
		font-weight: 600;
		border-left: 2px solid $transparent;
		border-radius: 0;
		padding-left: 30px;
		width: initial;
		+ .btn.btn-profile-data-left {
			margin-top: 20px;
		}
		&:active {
			box-shadow: none;
		}
		&.active {
			border-left: 2px solid $blue;
			color: $blue;
		}
	}
	.personal-data-row {
		display: flex;
		flex-flow: row;
		> div {
			flex: 1;
			margin-bottom: 35px;
		}
		.gender-wrapper {
			display: flex;
			flex-flow: column;
		}
		.gender-options-wrapper {
			display: flex;
			flex-flow: row;
			height: 42px;
			> div {
				position: relative;
			}
			> div + div {
				margin-left: 47px;
			}
			@include customRadio();
		}
		p,
		label {
			font-size: 16px;
			color: $black;
			font-weight: 500;
			margin-bottom: 30px;
		}
		input[type='text'] {
			height: 42px;
			border: 1px solid $grey;
			border-radius: 4px;
			min-width: 325px;
			padding: 14px 0 11px 26px;
			letter-spacing: 0.7px;
			font-size: 14px;
			color: $black;
		}
	}
	.profile-data-work,
	.profile-data-interests {
		.work-block,
		.interests-block {
			> p {
				font-size: 16px;
				font-weight: 500;
				color: $black;
				margin-bottom: 35px;
			}
		}
		.work-block-row,
		.interests-block-row {
			display: flex;
			flex-flow: row wrap;
		}
		.work-block-elem {
			@include customRadio();
			flex: 1 0 33%;
			margin-bottom: 30px;
		}
	}
	.interests-block-elem {
		@include customCheckbox();
		flex: 1 0 33%;
		max-width: 33%;
		.span-title {
			position: relative;
			top: -5px;
		}
	}
	.profile-data-place-of-residence,
	.profile-data-family {
		.place-of-residence-block,
		.family-block {
			margin-bottom: 30px;
			> div {
				@include customRadio();
			}
			p {
				font-size: 16px;
				font-weight: 500;
				color: $black;
				margin-bottom: 35px;
			}
		}
	}
	.family-block {
		&.marital-status > div {
			display: flex;
			flex-flow: row wrap;
			.container {
				flex: 1 0 50%;
				margin-bottom: 33px;
			}
		}
		&.household-members > div,
		&.children-under-full-age > div {
			display: flex;
			flex-flow: row wrap;
			.container {
				flex: 1 0 20%;
			}
		}
		&.income-per-one-person > div,
		&.housing-situation > div {
			display: flex;
			flex-flow: row wrap;
			.container {
				flex: 1 0 33%;
				margin-bottom: 33px;
				max-width: 33%;
			}
		}
	}
	.profile-data-place-of-residence {
		display: flex;
		flex-flow: row wrap;
		.place-of-residence-block {
			flex: 1 0 50%;
			> div {
				display: flex;
				flex-flow: row wrap;
				.container {
					flex: 1 0 50%;
					margin-bottom: 20px;
				}
			}
		}
	}
}
.panelist-dashboard-profile-data-buttons-wrapper {
	margin-top: 50px;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-end;
	.btn {
		&.btn-red {
			margin-right: 10px;
		}
	}
}
.panelist-competition-header-row,
.panelist-competition-data-row {
	display: flex;
	flex-flow: row wrap;
	height: 75px;
	line-height: 75px;
	border-bottom: 1px solid $grey;
}
.panelist-competition-header-cell,
.panelist-competition-data-cell {
	& {
		display: flex;
	}
	&.competition,
	&.created,
	&.points {
		font-weight: bold;
		font-size: 12px;
		color: $black;
	}
	&.competition {
		flex: 9;
		padding-left: 35px;
	}
	&.created {
		flex: 2;
		justify-content: center;
	}
	&.points {
		flex: 2;
		padding-right: 50px;
		justify-content: center;
	}
}
.panelist-competition-data-cell {
	&.competition,
	&.created {
		font-weight: normal;
	}
	&.competition,
	&.created,
	&.points {
		font-size: 14px;
	}
	&.points {
		font-weight: 500;
	}
}
.panelist-expand-button {
	margin-top: 30px;
	margin-bottom: 30px;
	color: $blue;
	font-size: 12px;
	font-weight: 500;
	&:after {
		@include chevron(45deg, $blue);
		display: inline-block;
		position: relative;
		left: 10px;
		top: -2px;
	}
	&:hover {
		cursor: pointer;
	}
}
.panelist-dashboard-pagination {
	margin-top: 55px;
	margin-bottom: 70px;
}

.capitalize {
	text-transform: capitalize;
}
