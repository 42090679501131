.companies-content-packet-title {
  font-size: 26px;
  font-weight: 500;
}

.companies-content-packet-subtitle {
  font-size: 16px;
  font-weight: 500;
  padding: 30px 0 30px;
}

.companies-content-packet-container {
  width: 70%;
  border: 1px solid $grey;
  box-shadow: 0 2px 11px 0 $sidebar-gradient-gray-right;
  padding: 30px 40px;
}

.companies-content-packet-price {
  font-size: 28px;
  font-weight: 300;
  padding-bottom: 10px;
}

.companies-content-packet-label {
  font-size: 14px;
}

.companies-content-packet-date {
  display: flex;
  align-items: center;
  padding: 38px 0 20px;
}

.companies-content-packet-text {
  font-size: 16px;
  font-weight: 500;
  padding-right: 39px;
}

.companies-content-packet-date-date {
  font-size: 14px;
}

.companies-content-packet-information {
  width: 40%;
  font-size: 14px;
  line-height: 1.64;
  letter-spacing: 0.4px;
  padding-bottom: 34px;
}

.settings-content-packet-boxes {
  width: 100%;
  border: 1px solid #ceced4;
  box-shadow: 0 11px 38px 0 rgba(87, 87, 87, 0.13);
  background-color: $white;
  position: absolute;
  top: 0;
  left: 0;
  padding: 60px 30px 0;
  margin-bottom: 80px;
}

.settings-content-packet-close {
  position: absolute;
  right: 30px;
  top: 18px;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.settings-content-packet-close:before,
.settings-content-packet-close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 15px;
  width: 2px;
  background-color: $blue;
}

.settings-content-packet-close:before {
  transform: rotate(45deg);
}

.settings-content-packet-close:after {
  transform: rotate(-45deg);
}

.settings-content-packet-all {
  font-size: 26px;
  font-weight: 500;
}

.settings-content-packet-change {
  font-size: 16px;
  font-weight: 500;
  padding: 18px 0 50px;
}
