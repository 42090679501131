.dashboard-container {
	height: 100%;
	padding-top: 63px;
}

.dashboard-card {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: $white;
	height: 110px;
	flex: 1;
	box-shadow: -7px 14px 16px 0 rgba(230, 237, 239, 0.54);
	&.two {
		flex: 0 1 48%;
	}
	&.four {
		flex: 0 1 23%;
	}
}

.dashboard-card-content {
	padding: 29px 13px 27px 16px;
}

.dashboard-card-text {
	h2 {
		font-size: 22px;
		font-weight: 300;
		margin-bottom: 10px;
		color: $lighter-black;
	}

	p {
		font-size: 14px;
	}
}

.dashboard-card-icon {
	width: 25px;
	margin: 30px 20px 0 0;
}

.dashboard-header-title {
	font-size: 26px;
	font-weight: 500;
	padding: 63px 0 40px;
}

.dashboard-header-survey-list-title {
	font-size: 26px;
	font-weight: 500;
	padding: 63px 0 40px;
}

.dashboard-card-list {
	display: flex;
	justify-content: space-between;
}

.dashboard-button-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 44px;
	.btn.btn-red:after {
		content: '';
		width: 8px;
		height: 8px;
		border-bottom: 2px solid $white;
		border-right: 2px solid $white;
		transform: rotate(45deg);
		position: absolute;
		top: 12px;
		right: 25px;
	}
}

// Panelists

.dashboard-header-panelists-tiles {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 220px;
}

.dashboard-header-panelists-tiles-left {
	width: 50%;
	display: flex;
	flex-direction: row;
	position: relative;
	padding: 30px 60px;
	padding-right: 15px;
	box-shadow: -7px 14px 16px 0 rgba(230, 237, 239, 0.54);
	background-color: #fff;
	h2 {
		font-size: 18px;
		font-weight: 500;
		margin-bottom: 15px;
	}
	p {
		font-size: 12px;
		font-weight: normal;
	}
	button {
		position: absolute;
		bottom: 30px;
		left: 60px;
	}
	div {
		display: flex;
		flex-direction: column;
		width: 50%;
		img {
			height: 100%;
			width: 100%;
		}
	}
}

.dashboard-header-panelists-tiles-right {
	margin-left: 70px;
	width: 50%;
	display: flex;
	flex-direction: column;
	padding: 30px 60px;
	background-color: #fff;
	box-shadow: -7px 14px 16px 0 rgba(230, 237, 239, 0.54);
	background-image: linear-gradient(292deg, #c63e6d, #f6426b);
	color: #fff;
	h3 {
		font-size: 18px;
		font-weight: 500;
	}
	h2 {
		font-size: 54px;
		font-weight: 500;
		margin: 20px 0;
	}
	p {
		font-size: 12px;
		font-weight: normal;
		width: 200px;
	}
}

.dashboard-header-panelists-greeting {
	font-size: 42px;
	font-weight: 500;
	margin-bottom: 50px;
}
